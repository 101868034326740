import React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput, Button as SubmitBtn } from "../../components/form-fields";
import {
  addCategory,
  updateCategory,
} from "../../redux/actions/category-action-types";
import { push } from "connected-react-router";
import { updateMany } from "../../firebase/methods";
import { toast } from "react-toastify";

const CategorySchema = Yup.object({
  title: Yup.string()
    .trim()
    .required("Title is a required field."),
});

const AddEditCategoryModal = ({ isOpen = false, toggle, category }) => {
  const dispatch = useDispatch();

  let modalTitle = "Create a new category",
    buttonTitle = "Save",
    savingMsg = "Saving ...";

  if (category) {
    modalTitle = "Update Category";
    buttonTitle = "Update";
    savingMsg = "Updating ...";
  }

  const formInitialValues = { title: category ? category.title : "" };

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmit = (values) => {
    try {
      setIsSubmitting(true);
      const callback = async () => {
        setIsSubmitting(false);
        if (category) {
          await updateMany(
            "subCategories",
            "parentCategoryId",
            category.id,
            "==",
            {
              parentCategory: values.title,
            }
          );

          await updateMany(
            "products",
            "categoryId",
            category.id,
            "==",
            {
              categoryName: values.title,
            }
          );
        }

        toggle();
        // dispatch(push('/sub'))
      };

      if (!category) dispatch(addCategory({ values, callback }));
      else dispatch(updateCategory({ id: category.id, values, callback }));
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader className="modal-header px-4" toggle={toggle}>
        <div className="modal-title" id="#addCategorylabel">
          {modalTitle}
        </div>
      </ModalHeader>

      <Formik
        initialValues={formInitialValues}
        validationSchema={CategorySchema}
        onSubmit={onSubmit}
      >
        {({ dirty, errors, isValidating, handleSubmit }) => (
          <Form className="pb-2">
            <ModalBody className="modal-body px-4 py-5 catpopup">
              <div>
                <div className="saveInput">
                  <div className="w-100">
                    <TextInput
                      labelclass="label-add-category p-0 text-uppercase"
                      label="Category Name"
                      name="title"
                      type="text"
                      placeholder="Title"
                      className="form-control py-4" 
                    />
                    <SubmitBtn
                      label={buttonTitle}
                      className="save-input-button position-absolute"
                      dirty={dirty}
                      errors={errors}
                      isValidating={isValidating}
                      isSubmitting={isSubmitting}
                      onClick={handleSubmit}
                      processingMsg={savingMsg}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddEditCategoryModal;
