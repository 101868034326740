import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  TextInput,
  Button as SubmitBtn,
  SelectInput,
} from "../../components/form-fields";
import {
  addSubCategoryField,
  updateSubCategoryField,
} from "../../redux/actions/sub-category-action-types";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { DynamicInputField } from "../../components/FieldForm";

const FormSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  defaultValue: Yup.string()
    .trim()
    .required("Default value is required"),
  isRequiredField: Yup.boolean().optional(),
  isUniqueField: Yup.boolean().optional(),
});

const AddSubcategoryFieldModal = ({
  isOpen = false,
  toggle,
  subCategoryField,
  id,
}) => {

  //console.log("Sub category field : ", subCategoryField);
  const dispatch = useDispatch();

  // const [initialValues, setInitialValues] = useState({
  //   subCategoryId:
  //     subCategoryField && subCategoryField.subCategoryId
  //       ? subCategoryField.subCategoryId
  //       : "",
  //   subCategory:
  //     subCategoryField && subCategoryField.subCategory
  //       ? subCategoryField.subCategory
  //       : "",
  //   fieldType:
  //     subCategoryField && subCategoryField.fieldType
  //       ? subCategoryField.fieldType
  //       : "",
  //   title:
  //     subCategoryField && subCategoryField.title ? subCategoryField.title : "",
  //   defaultValue:
  //     subCategoryField && subCategoryField.defaultValue
  //       ? subCategoryField.defaultValue
  //       : "",
  //   isRequiredField:
  //     subCategoryField && subCategoryField.isRequiredField
  //       ? subCategoryField.isRequiredField
  //       : false,
  //   isUniqueField:
  //     subCategoryField && subCategoryField.isUniqueField
  //       ? subCategoryField.isUniqueField
  //       : false,
  // });
  const [initialValues, setInitialValues] = useState(subCategoryField);
  const [formData, setFormData] = useState({});
  const [validationSchema, setValidationSchema] = useState({});

  useEffect(() => {
    initForm(FormSchema);
    if (id) {
      setInitialValues({
        ...initialValues,
        isRequiredField:
          subCategoryField && subCategoryField.isRequiredField
            ? subCategoryField.isRequiredField
            : false,
        isUniqueField:
          subCategoryField && subCategoryField.isUniqueField
            ? subCategoryField.isUniqueField
            : false,
        defaultValue: subCategoryField ? subCategoryField.defaultValue : "",
        imagePath: "",
      });
    }
  }, [subCategoryField]);

  const initForm = () => {
    let _validationSchema = {
      isRequiredField: Yup.boolean().optional(),
      isUniqueField: Yup.boolean().optional(),
    };

    if (initialValues.fieldType === "string") {
      _validationSchema["defaultValue"] = Yup.string().required(
        "Default value is required"
      );
    } else if (initialValues.fieldType === "number") {
      _validationSchema["defaultValue"] = Yup.number().required(
        "Default value is required"
      );
    } else if (initialValues.fieldType === "boolean") {
      _validationSchema["defaultValue"] = Yup.boolean().required(
        "Default value is required"
      );
    } else {
      _validationSchema["defaultValue"] = Yup.string().required(
        "Default value is required"
      );
    }

    setValidationSchema(
      Yup.object().shape({
        ..._validationSchema,
        title: Yup.string().required("Title is required"),
      })
    );
  };

  const handleChange = (e) => {
    if (initialValues.fieldType == "date") {
      if (!e.target) {
        setInitialValues({
          ...initialValues,
          defaultValue: e,
        });
      }
    } else {
      // console.log("E :", e.target);
      const { name, value } = e.target;
      setInitialValues({
        ...initialValues,
        [name]: value,
      });
    }

    //console.log("Initial values: ", initialValues);
  };

  // const validateInput = (input) => {
  //   const { fieldType } = initialValues;
  //   if (fieldType == "number") {
  //     console.log("Input :", input, parseInt(input));
  //     if (parseInt(input) == NaN) toast.error("Invalid input type");
  //     return false;
  //   } else if (fieldType == "boolean") {
  //     if (input !== "false" && input !== "true")
  //       toast.error("Invalid input type");
  //     return false;
  //   } else if (fieldType == "json") {
  //     try {
  //       toast.error("Invalid input type");
  //       JSON.parse(fieldType);
  //     } catch (error) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  // console.log("Inside add field modal initial values :", initialValues);

  const onSubmit = () => {
    let data = {
      ...initialValues,
      defaultValue:
        initialValues.fieldType == "date"
          ? moment(initialValues.defaultValue).format("x")
          : initialValues.defaultValue,
    };
    delete data.subCategory;

    //console.log("data : on submit  ", data)
    const callback = () => {
      dispatch(push(`/sub-category/${initialValues.subCategoryId}`));
    };
    if (id) {
      dispatch(updateSubCategoryField({ id, data: data, callback }));
    } else {
      dispatch(addSubCategoryField({ data: data, callback }));
    }
  };

  const setImageUrl = (url, path) => {
    setInitialValues({
      ...initialValues,
      defaultValue: url ? url : "",
      imagePath: path ? path : "",
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader className="modal-header px-4" toggle={toggle}>
        <div>{initialValues.subCategory}</div>

        {/* <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={toggle}
        >
          <span aria-hidden="true">×</span>
        </button> */}
      </ModalHeader>

      <ModalBody className="modal-body px-4 py-5">
        <div>
          <h6 className="modalBodyHeading">
            Add new {initialValues.fieldType}
          </h6>
          <div className="row">
            <div className="col-md-9">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({
                  dirty,
                  errors,
                  isValidating,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form className="mg-login">
                    <div className="mb-4 pb-2">
                      <TextInput
                        label="Title"
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        placeholder="Enter here.."
                        onChange={handleChange}
                        value={initialValues.title}
                        errors={errors}
                      />
                    </div>
                    <DynamicInputField
                      className="form-control"
                      label="Default Value"
                      id="defaultValue"
                      name="defaultValue"
                      placeholder="Enter here.."
                      fieldType={initialValues.fieldType}
                      onChange={(e) => handleChange(e)}
                      value={initialValues.defaultValue}
                      errors={errors}
                      urlValue={initialValues.defaultValue}
                      pathValue={initialValues.imagePath}
                      setFieldValue={setFieldValue}
                      setImageUrl={setImageUrl}
                      collection="subcategoryFields"
                      storagePath="/images/subCategoryFields"
                    />

                    <div className="mb-4 mt-4">
                      <div className="form-group">
                        <label htmlFor="subCATEGORY" className="mb-3">
                          Settings
                        </label>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                            checked={initialValues.isRequiredField}
                            onChange={(e) => {
                              // console.log("Initial values : ", initialValues);
                              setInitialValues({
                                ...initialValues,
                                isRequiredField: e.target.checked,
                              });
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck1"
                          >
                            Required field
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox mt-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck2"
                            checked={initialValues.isUniqueField}
                            onChange={(e) => {
                              setInitialValues({
                                ...initialValues,
                                isUniqueField: e.target.checked,
                              });
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Unique field
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-contentstart mb-5">
                      <button className="mb-5 sub-button mt-4" type="submit">
                        Done
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddSubcategoryFieldModal;
