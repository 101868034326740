import React, { useState } from "react";
import { toast } from "react-toastify";
import { Progress } from "reactstrap";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import { storage } from "../firebase";
import { update } from "../firebase/methods";

const FirebaseUpload = ({
  label,
  collection,
  accept,
  storagePath,
  required = true,
  id = null,
  urlValue,
  pathValue,
  urlError,
  pathError,
  arrUrlKey,
  arrPathKey,
  objUrlKey,
  setImageUrl,
  objPathKey,
}) => {
  /** State */
  const [isRemoving, setIsRemoving] = useState(false),
    [isUploading, setIsUploading] = useState(false),
    [progress, setProgress] = useState(0),
    [fileName, setFileName] = useState("");

  const handleUploadError = (error) => {
    setIsUploading(false);
    setProgress(0);
    toast.error(error.message);
  };

  const handleUploadSuccess = async (setImageUrl, filename) => {
    try {
      let fileUrl = await storage
        .ref(storagePath)
        .child(filename)
        .getDownloadURL();
      console.log("File Url : ", fileUrl);
      // if (id) await update(collection, id, { [objUrlKey]: fileUrl, [objPathKey]: `${storagePath}/${filename}` })
      setImageUrl(fileUrl, `${storagePath}/${filename}`);
      toast.success(`${label} uploaded successfully.`);
    } catch (error) {
      console.log("error.message", error.message);
      toast.error(
        `Some error occurred while saving ${label.toLowerCase()}: ${
          error.message
        }`
      );
    } finally {
      setIsUploading(false);
      setProgress(0);
    }
  };

  const removeFile = async (setImageUrl, filePath) => {
    try {
      setIsRemoving(true);
      await storage
        .ref()
        .child(filePath)
        .delete();
      setImageUrl("", "");
      if (id)
        // await update(collection, id, { [objUrlKey]: "", [objPathKey]: "" });
      toast.success(`${label} removed successfully.`);
    } catch (error) {
      toast.error(`Some error occurred while removing ${label.toLowerCase()}`);
    } finally {
      setIsRemoving(false);
    }
  };

  const onUploadStart = async (setImageUrl, filePath) => {
    try {
      setIsUploading(true);
      // setIsRemoving(true);
      if (filePath) {
        await storage
          .ref()
          .child(filePath)
          .delete();
        setImageUrl([arrUrlKey], "");
        setImageUrl([arrPathKey], "");
        // if (id)
        //   await update(collection, id, { [objUrlKey]: "", [objPathKey]: "" });
      }
      // toast.success(`${label} removed successfully.`);
    } catch (error) {
      toast.error(`Some error occurred while removing ${label.toLowerCase()}`);
    }
  };

  const _setFileName = (file) => {
    let fileName = `${file.name.split(".")[0]}-${Date.now()}.${file.name
      .split(".")
      .pop()}`;
    setFileName(fileName);
    return fileName;
  };

  return (
    <>
      {/* {console.log("id ===============>", id)} */}
      {/* {console.log("Values in FirebaseUpload  ", values)} */}
      {/* {console.log("errors in FirebaseUpload  ", errors)} */}
      {/* {console.log("arrUrlKey", arrUrlKey)} */}
      {pathValue && (
        <>
          {accept.includes("image") ? (
            <>
              <img
                alt="file-img"
                src={urlValue}
                className="mt-3 mb-3 img-responsive"
                width="500"
                height="auto"
              />
              <br />
            </>
          ) : (
            <p>
              {pathValue
                .split("/")
                .slice(-1)
                .pop()}
            </p>
          )}
          {!required && (
            <button
              onClick={() => removeFile(setImageUrl, pathValue)}
              className="btn btn-danger mr-2 "
              disabled={isRemoving}
            >
              Remove {label}
            </button>
          )}
        </>
      )}
      {isUploading && (
        <div>
          <Progress color="success" value={progress} />
          <div className="text-center">
            {" "}
            {fileName} - {progress}%
          </div>
        </div>
      )}
      <CustomUploadButton
        disabled={isUploading ? true : false}
        accept={accept}
        // name={fieldName}
        filename={_setFileName}
        storageRef={storage.ref(storagePath)}
        onUploadStart={() => onUploadStart(setImageUrl, pathValue)}
        onUploadError={handleUploadError}
        onUploadSuccess={(filename) =>
          handleUploadSuccess(setImageUrl, filename)
        }
        onProgress={(progress) => setProgress(progress)}
        className={`mt-2 btn btn-success ${isUploading ? "uploading" : ""}`}
      >
        {pathValue ? `Remove & Upload new ${label}` : `Upload ${label}`}
      </CustomUploadButton>
      {(urlError || pathError) && !urlValue && (
        <div className="form-err">{urlError || pathError}</div>
      )}
    </>
  );
};

export default FirebaseUpload;
