import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput, ReactMultiSelect } from "../../components/form-fields";
import {
  updateSubCategory,
  addSubCategory,
} from "../../redux/actions/sub-category-action-types";
import { updateMany } from "../../firebase/methods";

const CategorySchema = Yup.object({
  title: Yup.string()
    .trim()
    .required("Title is a required field."),
  parentCategoryId: Yup.string()
    .trim()
    .required("Parent category is required"),
});

const AddEditSubCategoryModal = ({
  isOpen = false,
  toggle,
  nextToggle,
  subCategory,
  id,
}) => {
  const dispatch = useDispatch();
  // console.log("Id >>", id);

  const categories = useSelector((state) => state.category?.categories);

  const categoryOptions = categories.map((category) => {
    return {
      key: id,
      label: category.title,
      value: category.id,
    };
  });

  let modalTitle = "Create a new subcategory",
    buttonTitle = "Save",
    savingMsg = "Saving ...";

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: "",
    parentCategory: "",
    parentCategoryId: "",
  });

  if (id) {
    modalTitle = "Update Category";
    buttonTitle = "Update";
    savingMsg = "Updating ...";
  }

  useEffect(() => {
    if (id) {
      // console.log("Id exists :", id, subCategory);
      setInitialValues({
        ...initialValues,
        title: subCategory.title,
        parentCategory: subCategory.parentCategory,
        parentCategoryId: subCategory.parentCategoryId,
      });
    } else {
      setInitialValues({
        title: "",
        parentCategory: "",
        parentCategoryId: "",
      });
    }
  }, [subCategory]);

  const handleChange = (e) => {
    setInitialValues({ ...initialValues, parentCategoryId: e.value });
  };

  // console.log("Initial values : ", initialValues);

  const onSubmit = (values) => {
    // console.log("Values :", values);
    setIsSubmitting(true);
    setIsSubmitting(false);

    categories.map((cat) => {
      if (cat.id == values.parentCategoryId) {
        values.parentCategory = cat.title;
        // console.log("Category matched :", cat);
      }
    });

    // console.log("Id :", id);
    if (id) {
      const callback = async (subCategory) => {
        await updateMany("products", "subCategoryId", id, "==", {
          subCategoryName: values.title,
        });
        toggle();
      };
      dispatch(
        updateSubCategory({
          id,
          data: { ...values },
          callback,
        })
      );
    } else {
      const callback = (data) => {
        // console.log("Inside else conditions", data);
        toggle();
        //nextToggle({ subCategory: values.title, subCategoryId: data.id });
      };

      dispatch(addSubCategory({ data: { ...values }, callback }));
    }

    // if (!category) dispatch(addCategory({ values, callback }));
    // else dispatch(updateCategory({ id: category.id, values, callback }));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true} >
      <ModalHeader className="modal-header px-4" toggle={toggle}>
        <div className="modal-title" id="#addCategorylabel">
          {modalTitle}
        </div>
      </ModalHeader>

      <Formik
        initialValues={initialValues}
        validationSchema={CategorySchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ dirty, errors, values, isValidating, handleSubmit }) => (
          <Form className="mg-login">
            <ModalBody className="modal-body px-4 pt-5 pb-0">
              <div className="row">
                <div className="col-md-9">
                  <div className="mb-4 ">
                    <ReactMultiSelect
                      label="SELECT A CATEGORY"
                      options={categoryOptions}
                      handleChange={handleChange}
                      name="parentCategoryId"
                      id="parentCategoryId"
                      value={categoryOptions.find((option) => {
                        return option.value == initialValues.parentCategoryId;
                      })}
                      placeholder="CHOOSE CATEGORY"
                      className="form-control form-select"
                      errors={errors}
                      isRequired
                    />
                  </div>
                  <div className="mb-4">
                    <div className="form-group">
                      <TextInput
                        label="Add A Sub Category"
                        name="title"
                        type="text"
                        placeholder="Title"
                        className="form-control py-4"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-contentstart mb-5">
                    <button className="mb-5 sub-button mt-4 px-5" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddEditSubCategoryModal;
