import { all, call, takeLatest, put } from "redux-saga/effects";
import { add, update } from "../../firebase/methods";
import { toast } from "react-toastify";
import {
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "../actions/category-action-types";
import { hideLoader, showLoader } from "../actions/app-action-types";

export function* addCategory({
  payload: {
    values: { title },
    callback,
  },
}) {
  try {
    yield put(showLoader());
    yield call(add, "categories", { title });
    yield put(hideLoader());
    if (callback) callback();
    toast.success("Category added successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    toast.error("An error occurred while creating category.");
  }
}

export function* updateCategory({ payload: { id, values: data, callback } }) {
  try {
    yield put(showLoader());
    yield call(update, "categories", id, data);
    yield put(hideLoader());

    if (callback) callback();
    toast.success("Category updated successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    toast.error("An error occurred while updating category.");
  }
}

export function* deleteCategory({ payload: { id, callback } }) {
  try {
    yield put(showLoader());
    yield call(update, "categories", id, { isDeleted: true });
    yield put(hideLoader());
    if (callback) callback();
    toast.success("Category deleted successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    toast.error("An error occurred while deleting category");
  }
}

function* Categories() {
  yield all([
    takeLatest(ADD_CATEGORY, addCategory),
    takeLatest(UPDATE_CATEGORY, updateCategory),
    takeLatest(DELETE_CATEGORY, deleteCategory),
  ]);
}

export default Categories;
