import { all, call, takeLatest, put } from "redux-saga/effects";
import { update} from "../../firebase/methods";
import { toast } from "react-toastify";
import {
  DELETE_USER
} from "../actions/user-action-types";

import { showLoader, hideLoader } from "../actions/app-action-types";

export function* deleteUser({ payload: { id, callback } }) {
  try {
    yield put(showLoader());
    yield call(update, "users", id, { isDeleted: true });
    yield put(hideLoader());
    if (callback) callback();
    toast.success("User deleted successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    toast.error("An error occurred while deleting user");
  }
}

// watcher saga
function* User() {
  yield all([
    takeLatest(DELETE_USER, deleteUser)
  ]);
}

export default User;
