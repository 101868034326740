
import React from 'react';
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalExample = ({ isOpen = false, toggle, id, method, content, collection = "" }) => {
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onClick = () => {
    setIsSubmitting(true);
    const callback = () => {
      setIsSubmitting(false);
      toggle();
    }
    collection
      ? dispatch(method({ collection, id, callback }))
      : dispatch(method({ id, callback }));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Delete Confirmation</ModalHeader>
      <ModalBody>
        <p>{content}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" type="submit" disabled={isSubmitting} onClick={onClick}>
          {isSubmitting ? (
            <>
              <i className="fal fa-spinner fa-spin mr-1" aria-hidden="true"></i>
              Deleting ...
            </>
          ) : "Delete"}
        </Button>
        <Button id="cancel-btn" color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>

    </Modal>
  );
}

export default ModalExample;