import {
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
} from "../actions/session-action-types";

const initialState = {
  isLoggedIn: false,
  user: {}
};
export default function session(state = initialState, { payload, type }) {
  switch (type) {

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload
      };

    case LOGOUT_SUCCESS:
      console.log("Initial State on logout", initialState)
      return { ...initialState };

    default:
      return state;
  }
}
