import {
  UPDATE_SUBCATEGORIES,
  UPDATE_SUBCATEGORY_FIELDS,
} from "../actions/sub-category-action-types";

import { LOGOUT_SUCCESS } from "../actions/session-action-types";

const initialState = {
  subCategories: [],
  subCategoryFields: [],
};

export default function(state = initialState, { payload, type }) {
  switch (type) {
    case UPDATE_SUBCATEGORIES:
      return {
        ...state,
        subCategories: payload,
      };

    case UPDATE_SUBCATEGORY_FIELDS:
      return {
        ...state,
        subCategoryFields: payload,
      };
    case LOGOUT_SUCCESS:
      console.log("Initial State on logout", initialState);
      return { ...initialState };

    default:
      return state;
  }
}
