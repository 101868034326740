import { all, call, takeLatest, put } from "redux-saga/effects";
import {
  add,
  update,
  deleteQ,
  getById,
  fetchSubCategoryFields,
} from "../../firebase/methods";
import { toast } from "react-toastify";
import {
  ADD_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  ADD_SUBCATEGORY_FIELD,
  UPDATE_SUBCATEGORY_FIELD,
  DELETE_SUBCATEGORY_FIELD,
  FETCH_SUBCATEGORY_FIELDS,
  FETCH_SUBCATEGORY,
  updateSubCategoryFields,
} from "../actions/sub-category-action-types";

import { showLoader, hideLoader } from "../actions/app-action-types";

export function* addSubCategory({ payload: { data, callback } }) {
  try {
    // console.log("Inside of this : ", data);
    yield put(showLoader());
    let doc = yield call(add, "subCategories", data);
    yield put(hideLoader());
    // console.log("Doc added ", doc);
    if (callback) callback(doc);
    toast.success("Sub category added successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    toast.error("An error occurred while creating sub category.");
  }
}

export function* updateSubCategory({ payload: { id, data, callback } }) {
  try {
    // console.log("Data: in Saga : ", data);
    yield put(showLoader());
    yield call(update, "subCategories", id, data);
    yield put(hideLoader());
    if (callback) callback();
    toast.success("Sub category updated successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    // console.log("Eror: ", error);
    toast.error("An error occurred while updating sub category.");
  }
}

export function* fetchSubCategory({ payload: { id, callback } }) {
  try {
    // console.log("Id: ", id);
    yield put(showLoader());
    let data = yield call(getById, "subCategories", id);
    yield put(hideLoader());
    // console.log("Data in saga: ", data);
    if (callback) callback(data);
    // toast.success("Sub category fetched successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    // console.log("Eror: ", error);
    toast.error("An error occurred while fetching sub category.");
  }
}

export function* deleteSubCategory({ payload: { id, callback } }) {
  try {
    yield put(showLoader());
    yield call(update, "subCategories", id, { isDeleted: true });
    yield put(hideLoader());
    if (callback) callback();
    toast.success("Sub category deleted successfully.");
  } catch (error) {
    yield put(hideLoader());
    // console.log("Error :", error);
    if (callback) callback();
    toast.error("An error occurred while deleting sub category");
  }
}

export function* addSubCategoryField({ payload: { data, callback } }) {
  try {
    yield put(showLoader());
    let doc = yield call(add, "subCategoryFields", data);
    yield put(hideLoader());
    if (callback) callback(doc);
    toast.success("Sub category field added successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    toast.error("An error occurred while creating sub category field.");
  }
}

export function* updateSubCategoryField({ payload: { id, data, callback } }) {
  try {
    // console.log("Data: in Saga : ", data);
    yield put(showLoader());
    yield call(update, "subCategoryFields", id, data);
    yield put(hideLoader());
    if (callback) callback();
    toast.success("Sub category field updated successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    // console.log("Eror: ", error);
    toast.error("An error occurred while updating sub category field.");
  }
}

export function* deleteSubCategoryField({ payload: { id, callback } }) {
  try {
    // console.log("Id: ", id);
    yield put(showLoader());
    yield call(deleteQ, "subCategoryFields", id);
    yield put(hideLoader());
    if (callback) callback();
    toast.success("Sub category field deleted successfully.");
  } catch (error) {
    yield put(hideLoader());
    // console.log("Error :", error);
    if (callback) callback();
    toast.error("An error occurred while deleting sub category field");
  }
}

/**List sub category fields from sub category id */
export function* listSubCategoryFields({ payload: { id, callback } }) {
  try {
    yield put(showLoader());
    let records = yield call(fetchSubCategoryFields, id);
    yield put(hideLoader());
    // console.log("Records : ", records);
    if (callback) callback();
    yield put(updateSubCategoryFields(records));
    // toast.success("Sub category fields fethced successfully.");
  } catch (error) {
    yield put(hideLoader());
    // console.log("Error :", error);
    if (callback) callback();
    toast.error("An error occurred while fetching sub category fields");
  }
}

function* SubCategories() {
  yield all([
    takeLatest(ADD_SUBCATEGORY, addSubCategory),
    takeLatest(UPDATE_SUBCATEGORY, updateSubCategory),
    takeLatest(DELETE_SUBCATEGORY, deleteSubCategory),
    takeLatest(ADD_SUBCATEGORY_FIELD, addSubCategoryField),
    takeLatest(UPDATE_SUBCATEGORY_FIELD, updateSubCategoryField),
    takeLatest(DELETE_SUBCATEGORY_FIELD, deleteSubCategoryField),
    takeLatest(FETCH_SUBCATEGORY_FIELDS, listSubCategoryFields),
    takeLatest(FETCH_SUBCATEGORY, fetchSubCategory),
  ]);
}

export default SubCategories;
