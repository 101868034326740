import React from "react";
import { connect } from "react-redux";
import ImageGallery from "react-image-gallery";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { push } from "connected-react-router";
import "react-image-gallery/styles/css/image-gallery.css";
import { fetchProduct, makeOffer, updateProduct } from "../../redux/actions/product-action-types";
import { getById } from "../../firebase/methods";
import { fetchSubCategoryFields } from "../../redux/actions/sub-category-action-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { getDate } from '../../utilities/methods';

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal1: false,
      verificationModal: false,
      productdetail: null,
      images: [],
      seller_info: null,
      user_detail: null
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    let self = this;
    this.props.fetchProduct({
      id, callback: async (data) => {
        //self.props.fetchSubCategoryFields({id:data.subCategoryId})
        let allimages = [];
        data.images.forEach((imgdata) => {
          allimages.push({
            original: imgdata.imageUrl,
            thumbnail: imgdata.imageUrl
          })
        });
        if (data.userId) {
          var seller = await getById("users", data.userId);
        }
        self.setState({
          productdetail: data,
          images: allimages,
          seller_info: seller,
        })

      }
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggle1 = () => {
    this.setState({
      modal1: !this.state.modal1,
    });
  };

  formatLabel = (str) => {
    let separateWord = str.toLowerCase().split(' ');
    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
        separateWord[i].substring(1);
    }
    return separateWord.join(' ');
  }

  render() {
    console.log("Subcategory Fields", this.props.subCategoryFields);
    const { verificationModal } = this.state;
    const { push } = this.props;
    const { productdetail, user_detail, seller_info } = this.state;
    console.log("User Detail", user_detail);
    console.log("Product Detail", productdetail);
    console.log("User detail", this.props.user);
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 py-4">
            {/*<div className="col-12 bg-orange py-3  mb-5 d-flex justify-content-between align-items-center border-radius4">
              <p className="text-white mb-0 font-18 ">
                {" "}
                This listing is unclaimed. If it belongs to you, click the
                button to claim
              </p>
              <div>
                <button
                  onClick={() => {
                    if(!this.props.isLoggedIn){
                      this.props.router.push("/")
                    }else{
                      this.toggle();
                    }
                    
                  }}
                  type="button"
                  data-toggle="modal"
                  data-target="#claim-listing"
                  className="claim-listing"
                >
                  Claim Listing
                </button>
                <span>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2812 3.71875L3.71875 13.2812"
                      stroke="white"
                      stroke-opacity="0.7"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.2812 13.2812L3.71875 3.71875"
                      stroke="white"
                      stroke-opacity="0.7"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>*/}
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-xl-7">
                  <div className="product--gallery">
                    <ImageGallery
                      showPlayButton={false}
                      showNav={false}
                      showFullscreenButton={false}
                      items={this.state.images}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-xl-5  product-details">
                  <div className="b-crum mb-4">
                    <ul className="m-0 p-0 d-flex">
                      <li>Category</li>
                      <li>{productdetail && productdetail.categoryName}</li>
                      <li>{productdetail && productdetail.subCategoryName}</li>
                    </ul>
                  </div>
                
                  <span className="offer-price font-weight-bold d-inline-block align-top pr-2 mr-2 mb-4">
                    ${productdetail && productdetail.buyNowPrice}
                  </span>
                  <div className="product-name mb-4">{productdetail && productdetail.name}</div>


                  <div className="descriptionAddress">
                      {seller_info ? (
                        <h4 className="mb-2">{`${seller_info.firstName} `}</h4>
                      ) : (
                        <h4 className="mb-2">NA</h4>
                      )}
                      
                      <span className="descriptionLocation mb-1">
                        <svg
                          width="20"
                          height="25"
                          viewBox="0 0 20 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.65304 0.506052L9.65312 0.506049C15.0415 0.316238 19.4689 4.62286 19.4689 9.96538C19.4689 10.5789 19.4104 11.1775 19.2983 11.7615L19.2975 11.7659L19.2975 11.766L19.2975 11.7661L19.2975 11.7661L19.2975 11.7662L19.2975 11.7662L19.297 11.7688L19.2945 11.7816C19.2922 11.7935 19.2886 11.812 19.2835 11.8366C19.2734 11.886 19.2576 11.9601 19.2355 12.0557L19.2346 12.0597C19.0887 12.7159 18.8748 13.3421 18.598 13.9339L18.594 13.9424L18.5904 13.951C17.6161 16.2551 15.3515 20.1494 10.2247 24.4007C10.0848 24.5156 9.88719 24.5122 9.75664 24.4028L9.7549 24.4014C4.62735 20.1446 2.36267 16.25 1.39353 13.9562L1.39052 13.9491L1.38729 13.9421C1.11378 13.3468 0.894741 12.7141 0.74906 12.064L0.749209 12.064L0.746204 12.052C0.722708 11.9581 0.707931 11.8885 0.699325 11.8444C0.695021 11.8224 0.69226 11.8067 0.690722 11.7976L0.68936 11.7894L0.689293 11.7889L0.689282 11.7889L0.689192 11.7882L0.687837 11.7787L0.685695 11.7675C0.519648 10.8942 0.466311 9.98422 0.558753 9.04617L0.558852 9.04515C1.0116 4.35208 4.93362 0.671476 9.65304 0.506052ZM4.64321 10.0832C4.64321 13.034 7.03679 15.4244 9.98937 15.4244C12.942 15.4244 15.3355 13.034 15.3355 10.0832C15.3355 7.1324 12.9419 4.74201 9.98937 4.74201C7.03679 4.74201 4.64321 7.1324 4.64321 10.0832Z"
                            fill="black"
                            stroke="black"
                          />
                        </svg>
                        {productdetail && productdetail.city}
                       
                        {productdetail &&
                          `${productdetail.country}, ${productdetail.postalCode}`}
                      </span>

                  
                    </div>

                  {/* <div className="container product--info p-0">
                    <div className="row mb-3 detail-info">
                      <div className="col-4 product-label">Location</div>
                      <div className="col-8  detail-txt">
                        {productdetail &&
                          `${productdetail.city}, ${productdetail.postalCode}`}
                      </div>
                    </div>
                    
                    <div className="row mb-3">
                      <div className="col-lg-4 product-label">Make</div>
                      <div className="col-lg-8">{productdetail && productdetail.make}</div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4 product-label">Model</div>
                      <div className="col-lg-8">{productdetail && productdetail.model}</div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4 product-label">Year</div>
                      <div className="col-lg-7">
                        {productdetail && (productdetail.year ? productdetail.year : "N/A")}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4 product-label">Condition</div>
                      <div className="col-lg-7">{productdetail && productdetail.condition}</div>
                    </div>
              
                  </div> */}
                  <div className="listingTableHeader d-flex justify-content-between align-items-center rqtfre">
                    {/* <div className="custom-control custom-checkbox ">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                      />
                      <label
                        className="custom-control-label"
                        for="customCheck1"
                      >
                        Request Free Freight Quote
                      </label>
                    </div> */}
                  </div>
                  {/* {productdetail && this.props.user && productdetail.userId !== this.props.user.uid && <div className="offerButtons d-flex align-items-center  flex-wrap">
                    <h2 className="m-0 font-weight-bold mr-4">${productdetail && productdetail.buyNowPrice}</h2>
                     <button
                      onClick={() => {
                        if(!this.props.isLoggedIn){
                          this.props.router.push("/")
                        }else{
                          this.toggle1();
                        }
                      }}
                      type="button"
                      data-toggle="modal"
                      data-target="#make-offer"
                      className="btn btn-custom text-white"
                    >
                      Make An Offer
                    </button> 
                  </div>} */}
                </div>
              </div>
            </div>

            <div className="col-12 pl-0 mt-5">
              <h5 className="mainHeading mb-3 text-dark">Description</h5>
              <div className="row pb-4">
                <div className="col-lg-8 col-xl-7 h-100  ">
                  <div className="descriptionInfo">
                    <p>
                      {productdetail && productdetail.description}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-5  align-items-center  ">
                  <div className="h-100 d-flex align-items-center">
                    {/* <div className="descriptionAddress">
                      {seller_info ? (
                        <h4 className="mb-2">{`${seller_info.firstName} `}</h4>
                      ) : (
                        <h4 className="mb-2">NA</h4>
                      )}
                      
                      <span className="descriptionLocation mb-1">
                        <svg
                          width="20"
                          height="25"
                          viewBox="0 0 20 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.65304 0.506052L9.65312 0.506049C15.0415 0.316238 19.4689 4.62286 19.4689 9.96538C19.4689 10.5789 19.4104 11.1775 19.2983 11.7615L19.2975 11.7659L19.2975 11.766L19.2975 11.7661L19.2975 11.7661L19.2975 11.7662L19.2975 11.7662L19.297 11.7688L19.2945 11.7816C19.2922 11.7935 19.2886 11.812 19.2835 11.8366C19.2734 11.886 19.2576 11.9601 19.2355 12.0557L19.2346 12.0597C19.0887 12.7159 18.8748 13.3421 18.598 13.9339L18.594 13.9424L18.5904 13.951C17.6161 16.2551 15.3515 20.1494 10.2247 24.4007C10.0848 24.5156 9.88719 24.5122 9.75664 24.4028L9.7549 24.4014C4.62735 20.1446 2.36267 16.25 1.39353 13.9562L1.39052 13.9491L1.38729 13.9421C1.11378 13.3468 0.894741 12.7141 0.74906 12.064L0.749209 12.064L0.746204 12.052C0.722708 11.9581 0.707931 11.8885 0.699325 11.8444C0.695021 11.8224 0.69226 11.8067 0.690722 11.7976L0.68936 11.7894L0.689293 11.7889L0.689282 11.7889L0.689192 11.7882L0.687837 11.7787L0.685695 11.7675C0.519648 10.8942 0.466311 9.98422 0.558753 9.04617L0.558852 9.04515C1.0116 4.35208 4.93362 0.671476 9.65304 0.506052ZM4.64321 10.0832C4.64321 13.034 7.03679 15.4244 9.98937 15.4244C12.942 15.4244 15.3355 13.034 15.3355 10.0832C15.3355 7.1324 12.9419 4.74201 9.98937 4.74201C7.03679 4.74201 4.64321 7.1324 4.64321 10.0832Z"
                            fill="black"
                            stroke="black"
                          />
                        </svg>
                        {productdetail && productdetail.city}
                       
                        {productdetail &&
                          `${productdetail.country}, ${productdetail.postalCode}`}
                      </span>

                  
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-4 pt-2">
              <h5 className="mainHeading mb-4 text-dark">
                Listing Information
              </h5>

              <div className="container product--info p-0">
                    <div className="row mb-3 detail-info">
                      <div className="col-4 product-label">Location</div>
                      <div className="col-8  detail-txt">
                        {productdetail &&
                          `${productdetail.city}, ${productdetail.postalCode}`}
                      </div>
                    </div>
                    
                    <div className="row mb-3">
                      <div className="col-lg-4 product-label">Make</div>
                      <div className="col-lg-8">{productdetail && productdetail.make}</div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4 product-label">Model</div>
                      <div className="col-lg-8">{productdetail && productdetail.model}</div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4 product-label">Year</div>
                      <div className="col-lg-7">
                        {productdetail && (productdetail.year ? productdetail.year : "N/A")}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4 product-label">Condition</div>
                      <div className="col-lg-7">{productdetail && productdetail.condition}</div>
                    </div>
              
                  </div>
              </div>
              <hr />

            <div className="mt-4 pt-2">
              <h5 className="mainHeading mb-4 text-dark">
                Additional Information
              </h5>

              <div className="col-12 p-0 addtnlInfo  m-0">
                <div className="w-100 row justify-content-between m-0">
                  {productdetail && productdetail?.extra && productdetail?.extra.map((extra, index) => {
                    return (
                      <div className="col-lg-12">
                        <div className="row mb-2" key={index}>
                          <div className="col-lg-4" style={{ overflow: "auto" }}>{this.formatLabel(extra.label)}</div>
                          <div className="col-lg-8" style={{ overflow: "auto" }}>
                            {/* <p className="mb-0">{extra.value ? (extra.value.length > 20 ? extra.value.slice(0, 20) + "..." : extra.value) : "Not applicable"}</p> */}
                            <p className="mb-0">{extra.value ? extra.value : "Not applicable"}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {/* <div className="col-lg-5">
                    <div className="row mb-2  ">
                      <div className="col-lg-7">Serial number</div>
                      <div className="col-lg-5">
                        <p className="mb-0">{productdetail && productdetail.serialnumber ? productdetail.serialnumber : "Not applicable"}</p>
                      </div>
                    </div>
                    <div className="row mb-2  ">
                      <div className="col-lg-7">Engine hours</div>
                      <div className="col-lg-5">
                        <p className="mb-0">{productdetail && productdetail.enginehours ? productdetail.enginehours : "Not applicable"}</p>
                      </div>
                    </div>
                    <div className="row mb-2 ">
                      <div className="col-lg-7">Header</div>
                      <div className="col-lg-5">
                        <p className="mb-0">{productdetail && productdetail.header ? productdetail.header : "Not applicable"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row mb-2  ">
                      <div className="col-lg-7">Stock number</div>
                      <div className="col-lg-5">
                        <p className="mb-0">{productdetail && productdetail.stocknumber ? productdetail.stocknumber : "Not applicable"}</p>
                      </div>
                    </div>
                    <div className="row mb-2  ">
                      <div className="col-lg-7">Combine type</div>
                      <div className="col-lg-5">
                        <p className="mb-0">{productdetail && productdetail.combinetype ? productdetail.combinetype : "Not applicable"}</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <div className="addtnlInfo d-flex justify-content-between">
                <div className="row w-100">
                  <div className="col-lg-5">
                    <div className="row mb-3">
                      <div className="col-lg-7">Registration Number</div>
                      <div className="col-lg-5">
                        <p className="mb-0">AAA 17916048</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-7">Accreditations</div>
                      <div className="col-lg-5">
                        <p className="mb-0">MAS</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-7">Teeth</div>
                      <div className="col-lg-5">
                        <p className="mb-0">Not Mounthed</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-7">HGP treated</div>
                      <div className="col-lg-5">
                        <p className="mb-0">No</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-7">Fertility</div>
                      <div className="col-lg-5">
                        <p className="mb-0">Wil Guarantee Fertile</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-7">Scrotal Circm</div>
                      <div className="col-lg-5">
                        <p className="mb-0">Average 38.1 cm</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-lg-offset-2"></div>
                  <div className="col-lg-5">
                    <div className="row mb-3">
                      <div className="col-lg-6">Pasture/Feed</div>
                      <div className="col-lg-6">
                        <p className="mb-0">Limited Grain & Pasture</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">Frame</div>
                      <div className="col-lg-6">
                        <p className="mb-0">Frame</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">Condition</div>
                      <div className="col-lg-6">
                        <p className="mb-0">Condition</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">Vaccinations</div>
                      <div className="col-lg-6">
                        <p className="mb-0">
                          Vira. Shield 6+ VL5 , MultiMin 90
                        </p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">Tick Status</div>
                      <div className="col-lg-6">
                        <p className="mb-0">Ticket Free Zone</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">Horns</div>
                      <div className="col-lg-6">
                        <p className="mb-0">Polled</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <hr />
            {productdetail && productdetail.latlng && <div className="mt-4 pt-2">
              <h5 className="mainHeading mb-2 text-dark">Location</h5>
              <span className="mb-2 d-block"> {productdetail &&
                          `${productdetail.country}, ${productdetail.postalCode}`}</span>
              <div className="map mb-5">
                <iframe
                  src={`https://maps.google.com/maps?q=${productdetail.latlng.lat},${productdetail.latlng.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                  width="100%"
                  height="320"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>}
          </div>
        </div>
        <Modal
          id="invitationCodeModal"
          isOpen={this.state.modal}
          toggle={() => {
            this.toggle();
          }}
        >
          <ModalBody className="modal-body px-4 py-5">
            <h4 className="text-center  modal-heading">
              Enter invitation code to claim your page
            </h4>
            <div className="code-box my-5 d-flex justify-content-center">
              <Formik
                initialValues={{
                  input1: "",
                  input2: "",
                  input3: "",
                  input4: "",
                  input5: "",
                  input6: ""
                }}
                validationSchema={Yup.object({
                  input1: Yup.string()
                    .min(1, 'Must be 1 character')
                    .required('Enter valid code'),
                  input2: Yup.string()
                    .min(1, 'Must be 1 character')
                    .required('Enter valid code'),
                  input3: Yup.string()
                    .min(1, 'Must be 1 character')
                    .required('Enter valid code'),
                  input4: Yup.string()
                    .min(1, 'Must be 1 character')
                    .required('Enter valid code'),
                  input5: Yup.string()
                    .min(1, 'Must be 1 character')
                    .required('Enter valid code'),
                  input6: Yup.string()
                    .min(1, 'Must be 1 character')
                    .required('Enter valid code')
                })}
                onSubmit={(values, { setSubmitting }) => {
                  console.log("hello world");
                  let finalcode = values.input1 + values.input2 + values.input3 + values.input4 + values.input5 + values.input6;
                  console.log(finalcode.trim().toUpperCase(), productdetail.shareCode);
                  if (productdetail && productdetail.shareCode != finalcode.trim().toUpperCase()) {
                    toast.error("Code is not valid");
                  } else {
                    this.setState({
                      modal: false,
                      verificationModal: true
                    })
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className=""
                  >
                    <span>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.input1}
                        name="input1"
                        type="text"
                        placeholder="-"
                      />
                    </span>
                    <span>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.input2}
                        name="input2"
                        type="text"
                        placeholder="-"
                      />
                    </span>
                    <span>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.input3}
                        name="input3"
                        type="text"
                        placeholder="-"

                      />
                    </span>
                    <span>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.input4}
                        name="input4"
                        type="text"
                        placeholder="-"
                      />
                    </span>
                    <span>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.input5}
                        name="input5"
                        type="text"
                        placeholder="-"

                      />
                    </span>
                    <span>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.input6}
                        name="input6"
                        type="text"
                        placeholder="-"

                      />
                    </span>
                    <div className="d-flex justify-content-center">
                      {errors.input1
                        || errors.input2
                        || errors.input3
                        || errors.input4
                        || errors.input5
                        || errors.input6
                        && <p className="error">Please input valid code</p>
                      }
                    </div>
                    <div className="d-flex justify-content-center ">
                      <button
                        type="submit"

                        className="sub-button px-5 mt-5"
                      >
                        Verify
                      </button>
                    </div>
                  </Form>
                )
                }
              </Formik>

            </div>

          </ModalBody>
        </Modal>

        <Modal
          id="make-offer"
          isOpen={this.state.modal1}
          toggle={() => {
            this.toggle1();
          }}
        >
          <ModalHeader
            className="d-flex bg-white text-dark px-0"
            toggle={() => {
              this.toggle1();
            }}
          >
            <h5 className="modal-title mr-3 black-light">
              Buy this Listing with
              <span className="black-text ml-2">Secure payment</span>
            </h5>
          </ModalHeader>
          <ModalBody className="modal-body px-4 py-4">
            <div className="row m-0">
              <div className="col-md-4 pl-md-0">
                <img src={this.state.images && this.state.images[0] && this.state.images[0].thumbnail} alt="pro" />
              </div>
              <div className="col-md-8 d-flex flex-row flex-wrap">
                <h3 className="black-text offer-name">{productdetail && productdetail.name}</h3>
                <p className="offer-dis">
                  Secure payment allows buyers and sellers to negotiate safely
                  and securely online. we verify funds, monitor the exchange of
                  payment and ensure the entire transaction is completed
                  hassle-free.
                </p>
                <div className="align-self-end">
                  <button onClick={() => { this.makeAnOffer() }} className="sub-button px-5 m-width264 text-white">
                    Make An Offer
                  </button>
                </div>
              </div>
              <p className="text-center w-100 mt-5 black-light">
                After Clicking Make an Offer you will be directed to Escrow.com
              </p>
              <p className="text-center w-100 black-light mb-0">
                <span className="mr-2">Powered by</span>
                <svg
                  width="22"
                  height="24"
                  viewBox="0 0 22 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.008 23.253H10.8129C10.7154 23.1555 -0.286879 20.2293 0.00573469 3.882V3.64791L1.50782 1.50208L1.80043 1.46307C1.9955 1.42405 11.008 0 11.008 0C11.008 0 20.0595 1.40454 20.2546 1.46307L20.4887 1.50208L21.9907 3.64791V3.882C22.1858 12.3483 19.2597 17.1666 16.7822 19.7026C14.0902 22.4727 11.2616 23.253 11.008 23.253ZM1.52732 4.17461C1.33225 18.103 9.66197 21.2632 11.0275 21.6924C12.432 21.2632 20.7032 18.0835 20.5277 4.11609L19.6108 2.80909C18.5964 2.575 14.5974 1.59962 11.047 1.50208C7.49663 1.59962 3.55611 2.575 2.48319 2.80909L1.52732 4.17461Z"
                    fill="#01426A"
                  />
                  <path
                    d="M15.4373 10.1635H7.26367V11.7631H15.4373V10.1635Z"
                    fill="#01426A"
                  />
                  <path
                    d="M15.4373 14.2015H7.26367V15.8011H15.4373V14.2015Z"
                    fill="#01426A"
                  />
                  <path
                    d="M15.4373 6.12537H7.26367V7.72499H15.4373V6.12537Z"
                    fill="#01426A"
                  />
                  <path
                    d="M7.22336 12.4068C6.44306 12.4068 5.7603 11.724 5.7603 10.9047C5.7603 10.1244 6.38454 9.40264 7.22336 9.40264H11.0664V8.29071H7.16484C5.70177 8.29071 4.58984 9.46116 4.58984 10.9242C4.58984 12.3873 5.7603 13.5577 7.16484 13.5577H11.0664V12.4068H7.22336Z"
                    fill="#42C31D"
                  />
                </svg>
              </p>
            </div>
          </ModalBody>
          <ModalFooter className="-flex justify-content-center w-100">
            <a className="text-sky my-3" href="#">
              Learn more about secure payment
            </a>
          </ModalFooter>
        </Modal>
        {verificationModal && (
          <Modal
            isOpen={this.state.verificationModal}
            toggle={() => {
              this.setState({ verificationModal: !verificationModal });
            }}
            onClick={() => push("/products")}
          >
            <div class="modal-body p-5">
              <h4 class="text-center  modal-heading">
                Verification Successful
              </h4>

              <div class="d-flex justify-content-center my-5">
                <svg
                  width="133"
                  height="133"
                  viewBox="0 0 133 133"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="66.5" cy="66.5" r="66.5" fill="#42C31D" />
                  <path
                    d="M98.625 46.877L58.375 87.1252L38.25 67.002"
                    stroke="white"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  {
    subCategory: {
      subCategoryFields
    },
    session: {
      isLoggedIn,
      user
    }
  }
) => (
  {
    subCategoryFields,
    isLoggedIn,
    user
  }
);

export default connect(mapStateToProps, {
  push,
  fetchProduct,
  makeOffer,
  fetchSubCategoryFields,
  updateProduct
})(ProductDetail);
