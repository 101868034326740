import { push } from "connected-react-router";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { signIn, getIdToken } from "../../firebase/auth";
import { toast } from "react-toastify";
import axiosInstance from "../../utilities/axios-instance";
import {
  LOGOUT,
  LOGIN,
  loginSuccess,
  logoutSuccess,
} from "../actions/session-action-types";

import { showLoader, hideLoader } from "../actions/app-action-types";

export function* logout({ payload }) {
  yield put(logoutSuccess());
  yield put(push("/"));
}

export function* login({
  payload: {
    values: { email, password },
    callback,
  },
}) {
  try {
    yield put(showLoader());
    const response = yield call(signIn, { email, password });
    var user = response.user;
    if (!user) throw new Error("Error while log in.");

    var token = yield call(getIdToken, user);
    let data = {
      url: "/validate-admin",
      headers: { "X-Authorization": `Bearer ${token}` },
    };
    yield call(axiosInstance, data);
    yield put(loginSuccess(user));
    yield put(hideLoader());
    yield put(push("/dashboard"));
    toast.success("Logged in successfully.");
  } catch (error) {
    yield put(hideLoader())
    if (callback) callback();
    toast.error(error.message);
  }
}

function* Session() {
  yield all([
    takeLatest(LOGOUT, logout),
    takeLatest(LOGIN, login),
    // takeLatest(UPDATE_USER, UpdateUser)
  ]);
}

export default Session;
