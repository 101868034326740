import React from "react";
import { Switch } from "react-router-dom";
import AppRoute from "./appRoutes";
import Routes from "./routers";

const Routers = ({ store, history }) => {
  let {
    session: { isLoggedIn },
  } = store.getState();

  return (
    <Switch>
      {Routes.map(
        ({
          component: Component,
          path,
          type,
          to,
          title,
          className = "",
          requiredPermission,
        }) => (
          <AppRoute
            key={title}
            exact
            title={title}
            component={Component}
            path={path}
            store={store}
            type={type}
            to={to}
            className={className}
            history={history}
            isLoggedIn={isLoggedIn}
          />
        )
      )}
    </Switch>
  );
};
export default Routers;

