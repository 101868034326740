import { createAction } from "redux-actions";

export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const updateProducts = createAction(UPDATE_PRODUCTS);

export const ADD_PRODUCT = "ADD_PRODUCT";
export const addProduct = createAction(ADD_PRODUCT);

export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const fetchProduct = createAction(FETCH_PRODUCT);

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const updateProduct = createAction(UPDATE_PRODUCT);

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const deleteProduct = createAction(DELETE_PRODUCT);

export const MAKE_OFFER = "MAKE_OFFER";
export const makeOffer = createAction(MAKE_OFFER);

export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const createCustomer = createAction(CREATE_CUSTOMER);