import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Button, FormFeedback } from "reactstrap";
import Regex from "../utilities/regex";
import { push } from "connected-react-router";
import { login } from "../redux/actions/session-action-types";

function Login() {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
      email: "",
      password: "",
    }),
    [showPassword, toggleShowPassword] = useState(false),
    [isSubmitting, setIsSubmitting] = useState(false),
    [errors, setErrors] = useState({
      email: false,
      password: false,
    });

  const onHandleChange = ({ target: { name, value } }) => {
    if(name === "email") {
      setErrors({
        errors: {
          ...errors,
          [name]: false,
        },
      });
  
      setInitialValues({
        ...initialValues,
        [name]: value.replace(/\s/g, ''),
      });
    }else {
      setErrors({
        errors: {
          ...errors,
          [name]: false,
        },
      });
  
      setInitialValues({
        ...initialValues,
        [name]: value,
      });
    }
   
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    let { email, password } = initialValues;
    if (!Regex.email(email.trim())) {
      setErrors({
        ...errors,
        email: true,
      });
    }
    if (password.trim() === "" || password.length < 6) {
      setErrors({
        ...errors,
        password: true,
      });
    }

    if (!errors.email && !errors.password) {
      setIsSubmitting(true);
      const callback = () => setIsSubmitting(false);
      dispatch(login({ values: initialValues, callback }));
    }
  };

  return (
    <div className="col-12 inner-page-content login-page px-0  login-100 d-flex justify-content-center align-items-center ">
      <div id="login" className="col-sm-12  py-4">
        <div className="login-container col-sm-8 col-md-6 col-lg-4 col-xl-4 m-auto bg-white boxshadow border-radius8">
          <div
            id="login-row"
            className="row justify-content-center align-items-center "
          >
            <div id="login-column" className="w-100">
              <h3 className="text-center page-head font-weight-bold  txtcolor">
                Sign In
              </h3>
              <h5 className="text-center txt-lghtblue page-subhead mb-4 pb-2">
                Enter your account details below
              </h5>
              <div id="login-box" className="col-md-12">
                <Form className="form site-form" onSubmit={handleSubmit}>
                  <FormGroup className="form-group  mb-4">
                    <Input
                      id="email"
                      className="form-control input-style min-input"
                      name="email"
                      placeholder="Email address/user name"
                      type="text"
                      value={initialValues.email}
                      onChange={onHandleChange}
                      invalid={
                        (initialValues &&
                          initialValues.email &&
                          !Regex.email(initialValues.email)) ||
                        errors.email
                      }
                    />
                    <FormFeedback id="email-err">
                      Please enter a valid email address.
                    </FormFeedback>{" "}
                  </FormGroup>
                  <div className="form-group position-relative  mb-2">
                    <FormGroup>
                      <Input
                        id="password"
                        type={showPassword ? "type" : "password"}
                        name="password"
                        onChange={onHandleChange}
                        className="form-control input-style min-input"
                        placeholder="Enter password"
                        value={initialValues.password}
                      />
                      <a
                        // href="/#"
                        className="pwd-show"
                        onClick={() => toggleShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </a>
                    </FormGroup>
                  </div>
                  <FormGroup>
                    {" "}
                    <div className="d-flex flex-wrap justify-content-end">
                      <Link to={process.env.PUBLIC_URL+"/forgot-password"}>
                        <a className="font-medium mb-4">Forgot Password?</a>
                      </Link>
                    </div>
                    <button
                      type="submit"
                      className="btn  text-white w-100 btn-custom min-input"
                    >
                      <span>Sign in</span>
                    </button>
                  </FormGroup>
                </Form>

                {/* <p className="w-100 text-center p-lght m-0">
                  New to Bid?{" "}
                  <Link to="/signup">
                    <a>Sign up</a>
                  </Link>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
