// // Global loader for whole application //
// // eslint-disable-next-line getter-return
// import React from "react";
// import { SyncLoader } from "halogenium";
// import { Modal } from "reactstrap";

// export default ({ loading = true }) => {
//   return (
//     loading && (
//       <div className="loader">
//         <Modal
//           isOpen={loading}
//           className="transparent_modal "
//           modalTransition={{ timeout: 100 }}
//           backdrop={false}
//         >
//           <SyncLoader color="#FF7E06" size="25px" margin="4px" />
//         </Modal>
//       </div>
//     )
//   );
// };

import React from "react";
import styled, { keyframes } from "styled-components";

const commonStyle = {
  margin: "auto",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

const sizeItem = {
  small: "10px",
  default: "12px",
  large: "14px",
};

const load = keyframes`
  0%{
    opacity: 1;
    transform: scale(1);
  }
  100%{
      opacity: 0;
      transform: rotate(90deg) scale(.3);
  }
`;

const LoadingContainer = styled.div`
  width: 100px;
  height: 100px;
`;

const ItemSpan = styled.span`
  display: inline-block;
  height: ${(props) => sizeItem[props.size] || sizeItem["default"]};
  width: ${(props) => sizeItem[props.size] || sizeItem["default"]};
  margin-top: 50%;
  margin-right: 5px;
  background: ${(props) => props.color || "#00adb5"};
  transform-origin: right bottom;
  animation: ${load} ${(props) => props.speed || 2}s ease infinite;
`;

const ItemSpan1 = styled(ItemSpan)`
  animation-delay: 0.2s;
`;

const ItemSpan2 = styled(ItemSpan)`
  animation-delay: 0.4s;
`;

const ItemSpan3 = styled(ItemSpan)`
  animation-delay: 0.6s;
`;
const ItemSpan4 = styled(ItemSpan)`
  animation-delay: 0.8s;
`;

const CircleLoading = ({
  style = commonStyle,
  speed,
  size = "40px",
  loading = true,
}) => {
  return (
    loading && (
      <LoadingContainer style={style}>
        <ItemSpan1 color="#FF7E06" speed={speed} size={size} />
        <ItemSpan2 color="#FF7E06" speed={speed} size={size} />
        <ItemSpan3 color="#FF7E06" speed={speed} size={size} />
        <ItemSpan4 color="#FF7E06" speed={speed} size={size} />
      </LoadingContainer>
    )
  );
};

export default CircleLoading;
