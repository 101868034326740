import firebase from 'firebase/app'

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

var config = {
  apiKey: "AIzaSyAGlJCceJGfAqkjM3Dd_uHx5eEcf6-yif0",
  authDomain: "bidcom-34469.firebaseapp.com",
  projectId: "bidcom-34469",
  storageBucket: "bidcom-34469.appspot.com",
  messagingSenderId: "339617476173",
  appId: "1:339617476173:web:99b1bc31ab346ca5cc3604",
  measurementId: "G-SWV3KFK6PV"
};


firebase.initializeApp(config);
var auth = firebase.auth();
var storage = firebase.storage();
var db = firebase.firestore();

console.log("Firebase")

export {
  db,
  auth,
  storage
};