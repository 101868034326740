import {getCurrentUserToken} from '../firebase/auth'
import { toast } from "react-toastify";
const path = require("path");
const moment = require("moment");
const axios = require("axios");


export const ucFirst = (str, ln) => {
  if (str) {
    let limit = str.length;
    if (limit > 0) {
      return (
        str
          .toLowerCase()
          .split(" ")
          // eslint-disable-next-line array-callback-return
          .map(function(word) {
            if (word) {
              return word[0].toUpperCase() + word.substr(1);
            }
          })
          .join(" ")
          .substring(0, ln) + (limit > ln ? "..." : "")
      );
    }
  }
};

export const ucFirstLetter = (str, ln) => {
  if (str) {
    let limit = str.length;
    if (limit > 0) {
      return (
        str.charAt(0).toUpperCase() +
        str.slice(1).substring(0, ln) +
        (limit > ln ? "..." : "")
      );
    }
  }
};

export const sortArrayOfObjects = (key = "created_at", order = "desc") => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) comparison = 1;
    else if (varA < varB) comparison = -1;

    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const checkFileType = (
  file,
  cb,
  filetypes = /jpeg|jpg|png|gif/,
  errMsg = "This type of file is not allowed."
) => {
  // Check ext
  const extname = filetypes.test(path.extname(file.originalname).toLowerCase());
  // Check mime
  const mimetype = filetypes.test(file.mimetype);

  if (mimetype && extname) {
    return cb(null, true);
  } else {
    cb(errMsg);
  }
};

export const getDate = (start) => {
  if (!start) {
    return '';
  }

  return `${moment(start).format('MMMM Do YYYY')}`;
};

export const capitalizeFirst = function(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const fetchFirstLetter = function(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase();
};

export const makeOfferMethod = async(params) => {
  const token = await getCurrentUserToken();
  console.log("Token", token);
  const config = { headers: { "x-authorization": `Bearer ${token}` } };
  try{
     const response = await axios.post(
      `https://us-central1-bidcom-34469.cloudfunctions.net/offer`,
       params,
       config
     ).catch(function (error) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        toast.error("Not able to process due to network error!");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
     });
     if(response){
      const data = response.data;
      return data
     }else{
       return response;
     }
     
  } catch (err) {
      console.log('error', err);
      toast.error(err);
  }
}

export const createCustomerMethod = async(params) => {
  try{
      const response = await axios.post(
        `https://us-central1-bidcom-34469.cloudfunctions.net/customer`,
        params
      )
      const data = response.data
      return data
  } catch (err) {
     console.log('error', err);
  }
}

export const getOffer = async(id) => {
    const token = await getCurrentUserToken();
    const config = { headers: { "x-authorization": `Bearer ${token}` } };
    try{
         const response = await axios.get(`
         https://us-central1-bidcom-34469.cloudfunctions.net/list-offers/${id}`, 
           config);

           const data = response.data
           return data
    } catch(err) {
      console.log('err', err);
    }
}
