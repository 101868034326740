import { createAction } from "redux-actions";

/**Update list of sub categories */
export const UPDATE_SUBCATEGORIES = "UPDATE_SUBCATEGORIES";
export const updateSubCategories = createAction(UPDATE_SUBCATEGORIES);

/*** Update single sub category */
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const updateSubCategory = createAction(UPDATE_SUBCATEGORY);

/*** Delete single sub category */
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const deleteSubCategory = createAction(DELETE_SUBCATEGORY);

/**Add single sub category */
export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const addSubCategory = createAction(ADD_SUBCATEGORY);

/**Add sub category field */
export const ADD_SUBCATEGORY_FIELD = "ADD_SUBCATEGORY_FIELD";
export const addSubCategoryField = createAction(ADD_SUBCATEGORY_FIELD);

/**Update sub category field */
export const UPDATE_SUBCATEGORY_FIELD = "UPDATE_SUBCATEGORY_FIELD";
export const updateSubCategoryField = createAction(UPDATE_SUBCATEGORY_FIELD);


/**Update sub category fields list */
export const UPDATE_SUBCATEGORY_FIELDS = "UPDATE_SUBCATEGORY_FIELDS";
export const updateSubCategoryFields = createAction(UPDATE_SUBCATEGORY_FIELDS);

/**Delete sub category field */
export const DELETE_SUBCATEGORY_FIELD = "DELETE_SUBCATEGORY_FIELD";
export const deleteSubCategoryField = createAction(DELETE_SUBCATEGORY_FIELD);

/**Fetch sub category  */
export const FETCH_SUBCATEGORY = "FETCH_SUBCATEGORY";
export const fetchSubCategory = createAction(FETCH_SUBCATEGORY);

/**Update sub category fields */
export const FETCH_SUBCATEGORY_FIELDS = "FETCH_SUBCATEGORY_FIELDS";
export const fetchSubCategoryFields = createAction(FETCH_SUBCATEGORY_FIELDS);
