import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = ({ isUploading, percentUploaded }) =>
  isUploading && (
    <div style={{ width: 80, height: 80 }}>
      <CircularProgressbar
        value={percentUploaded}
        text={percentUploaded}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0.25,
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: "butt",
          // Colors
          textColor: "#FF7E06",
          pathColor: "#FF7E06",
        })}
      />
    </div>
  );

export default ProgressBar;
