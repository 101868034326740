import React from "react";
import { Form, FormGroup, Input, FormFeedback } from "reactstrap";
import Regex from "../utilities/regex";
import { push } from "connected-react-router";
import { connect } from "react-redux";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {
        email: false,
      },
    };
  }
  onHandleChange = ({ target: { name, value } }) => {
    const { errors } = this.state;
    if(name === "email") {
      this.setState({
        errors: {
          ...errors,
          [name]: false,
        },
        [name]: value.replace(/\s/g, ''),
      });
    }else{
      this.setState({
        errors: {
          ...errors,
          [name]: false,
        },
        [name]: value,
      });
    }
    
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { email, errors } = this.state;
    const { push } = this.props;
    if (!Regex.email(email.trim())) {
      errors = {
        ...errors,
        email: true,
      };
    }

    this.setState({ ...this.state, errors });
    if (!errors.email && !errors.password) {
      push("/mail-sent");
    }
  };

  render() {
    const { email, errors } = this.state;
    return (
      <div className="col-12 inner-page-content login-page px-0  login-100 d-flex justify-content-center align-items-center ">
        <div id="login" className="col-sm-12 py-4">
          <div className="login-container col-sm-8 col-md-6 col-lg-4 col-xl-4 m-auto bg-white boxshadow border-radius8">
            <div
              id="login-row"
              className="row justify-content-center align-items-center"
            >
              <div id="login-column" className="w-100">
                <h3 className="text-center page-head font-weight-bold  txtcolor">
                  Forgot Password?
                </h3>
                <h5 className="text-center txt-lghtblue page-subhead mb-4 pb-2">
                  Enter your details to receive a reset link
                </h5>
                <div id="login-box" className="col-md-12">
                  <Form className="form site-form" onSubmit={this.handleSubmit}>
                    <FormGroup className="form-group mb-4">
                      <Input
                        id="email"
                        className="form-control input-style min-input"
                        name="email"
                        placeholder="Email address"
                        type="text"
                        value={email}
                        onChange={this.onHandleChange}
                        invalid={(email && !Regex.email(email)) || errors.email}
                      />
                      <FormFeedback id="email-err">
                        Please enter a valid email address.
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup className="form-group m-0">
                      <button
                        type="submit"
                        className="btn  text-white w-100 btn-custom min-input mt-2"
                        // onclick="window.location.href='mail-sent.html'; return false;"
                      >
                        <span>Send Reset Link</span>
                      </button>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { push })(ForgotPassword);
