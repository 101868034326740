import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="main-bggrey">
      <header className="bg-white header py-2">
        <div className="container">
          <nav className="w-100  d-flex justify-content-between flex-wrap py-1 align-items-center pb-logo">
            <div className="bid-logo ">
              <Link to={process.env.PUBLIC_URL+"/"}>
                <a className="navbar-brand">
                  <img src={process.env.PUBLIC_URL+"/assets/images/logo.svg"} alt="logo" />
                </a>
              </Link>
            </div>
            <div className="bid-nav">
              {/* <ul className="list-unstyled d-flex m-0 align-items-center">
                <li className="nav-item">
                  <Link to="/">
                    <a className="btn "> Log in </a>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/signup">
                    <a className="btn btn-custom "> Register </a>
                  </Link>
                </li>
              </ul> */}
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
