import React from "react";
import DatePicker from "react-date-picker";
import classNames from "classnames";
import { TextInput, TextArea, Checkbox, NumberInput } from "./form-fields";
import "./datepicker.css";
import FileUpload from "./firebaseUpload";
export const DynamicInputField = ({
  id,
  name,
  label,
  value,
  onChange,
  errors,
  fieldType,
  children,
  labelclass = "",
  setImageUrl,
  pathValue,
  urlValue,
  error,
  collection,
  storagePath,
  ...props
}) => {
  switch (fieldType) {
    case "string":
      return (
        <>
          <TextInput
            label={label}
            className="form-control"
            id={id}
            name={name}
            placeholder="Enter here.."
            onChange={onChange}
            value={value}
            errors={error}
          />
        </>
      );
    case "number":
      return (
        <>
          <NumberInput
            label={label}
            className="form-control"
            id={id}
            name={name}
            placeholder="Enter here.."
            onChange={onChange}
            value={value}
            errors={error}
          />
        </>
      );

    case "date":
      return (
        <>
          <div id="datepicker">
            <label className={labelclass} htmlFor={id}>
              {label}
            </label>

            <DatePicker
              className={classNames("form-control min-input", {
                "is-invalid": error,
              })}
              onChange={onChange}
              value={value}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              format="d/M/yyyy"
              // showLeadingZeros={true}
              calendarIcon={
                <span>
                  <i className="fa fa-calendar" aria-hidden="true" />
                </span>
              }
              {...props}
            />
            <div
              className="invalid-feedback"
              style={{
                display: !error ? "none" : "block",
              }}
            >
              {error ? error : ""}
            </div>
          </div>
        </>
      );
    case "media":
      return (
        <>
          <FileUpload
            label="Image"
            collection={collection}
            accept="image/*"
            storagePath={storagePath}
            setImageUrl={setImageUrl}
            id={id}
            value={value}
            keyName={name}
            urlValue={urlValue}
            pathValue={pathValue}
            urlError={errors?.imageUrl}
            pathError={errors?.imagePath}
          />
        </>
      );
    case "boolean":
      return (
        <>
          <TextInput
            label={label}
            className="form-control"
            id={id}
            name={name}
            placeholder="Enter here.."
            onChange={onChange}
            value={value}
            errors={error}
          />
        </>
      );
    case "relation":
      return (
        <>
          <TextInput
            label={label}
            className="form-control"
            id={id}
            name={name}
            placeholder="Enter here.."
            onChange={onChange}
            value={value}
            errors={error}
          />
        </>
      );
    case "description":
      return (
        <>
          <TextArea
            label={label}
            className="form-control"
            id={id}
            name={name}
            placeholder="Enter here.."
            onChange={onChange}
            value={value}
            errors={error}
          />
        </>
      );
    case "json":
      return (
        <>
          <TextInput
            label={label}
            className="form-control"
            id={id}
            name={name}
            placeholder="Enter here.."
            onChange={onChange}
            value={value}
            errors={error}
          />
        </>
      );
    default:
      return (
        <>
          <TextInput
            label={label}
            className="form-control"
            id={id}
            name={name}
            placeholder="Enter here.."
            onChange={onChange}
            value={value}
            errors={error}
          />
        </>
      );
  }
};
