import { createAction } from "redux-actions";

/**Update list of categories */
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const updateCategories = createAction(UPDATE_CATEGORIES);

/*** Update single category */
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const updateCategory = createAction(UPDATE_CATEGORY);

/*** Delete single category */
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const deleteCategory = createAction(DELETE_CATEGORY);

export const ADD_CATEGORY = "ADD_CATEGORY";
export const addCategory = createAction(ADD_CATEGORY);

