import { useField } from "formik";
import React from "react";
import Select from "react-select";
import * as Yup from "yup";

const modifyClasses = (className, meta) => {
  if (meta.touched) {
    let isInValid = meta.error;
    if (isInValid && !className.includes("is-invalid"))
      className = className + " is-invalid";
    if (!isInValid && !className.includes("is-valid"))
      className = className + " is-valid";
  }
  return className;
};

export const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <input type="checkbox" {...field} {...props} /> {children}
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};

export const TextInput = ({ label, labelclass, ...props }) => {
  const [field, meta] = useField(props);

  props.className = modifyClasses(props.className, meta);

  return (
    <>
      {label && (
        <label className={labelclass} htmlFor={props.id || props.name}>
          {label} {props.isRequired && <span style={{ color: "#E67A66" }}>*</span>}
        </label>
      )}
      <input {...field} {...props} />

      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};

export const NumberInput = ({ label, labelclass, ...props }) => {
  const [field, meta] = useField(props);

  props.className = modifyClasses(props.className, meta);
  return (
    <>
      {label && (
        <label className={labelclass} htmlFor={props.id || props.name}>
          {label} {props.isRequired && <span style={{ color: "#E67A66" }}>*</span>}
        </label>
      )}
      <input type="number" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};

export const FileInput = ({
  label,
  labelclass,
  onChange,
  className,
  name,
  accept,
  ...props
}) => {
  return (
    <>
      {label && (
        <label className={labelclass} htmlFor={props.id || props.name}>
          {label} {props.isRequired && <span style={{ color: "#E67A66" }}>*</span>}
        </label>
      )}
      <div className={className}>
        <svg
          width="64"
          height="54"
          viewBox="0 0 64 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.8207 21.6809C25.6147 21.6809 23.0156 24.2799 23.0156 27.4859C23.0156 30.6919 25.6146 33.291 28.8207 33.291C32.0266 33.291 34.6257 30.692 34.6257 27.4859C34.6257 24.2799 32.0267 21.6809 28.8207 21.6809ZM28.8207 30.5265C27.1413 30.5265 25.7799 29.1652 25.7799 27.4858C25.7799 25.8064 27.1413 24.4451 28.8207 24.4451C30.5 24.4451 31.8614 25.8064 31.8614 27.4858C31.8614 29.1652 30.5 30.5265 28.8207 30.5265Z"
            fill="#FF7E06"
          />
          <path
            d="M51.9704 8.41232L14.238 4.1278C12.7736 3.91959 11.2898 4.34711 10.1607 5.30266C9.03176 6.17806 8.30808 7.47568 8.15663 8.89618L7.46562 14.563H5.32316C2.28242 14.563 0.00186758 17.2581 0.00186758 20.2988V48.5635C-0.0747197 51.4631 2.21377 53.8759 5.11351 53.9525C5.18335 53.9543 5.25332 53.9543 5.32316 53.9543H43.2628C46.3036 53.9543 49.0679 51.6043 49.0679 48.5635V47.4578C50.0106 47.2757 50.9049 46.8991 51.6939 46.3521C52.8136 45.4094 53.5306 44.0744 53.698 42.6204L56.877 14.563C57.2011 11.5152 55.0139 8.77356 51.9704 8.41232ZM46.3037 48.5635C46.3037 50.0839 44.7832 51.1896 43.2628 51.1896H5.32316C3.94975 51.2299 2.80372 50.1492 2.76338 48.7758C2.76126 48.705 2.76227 48.6343 2.76615 48.5635V43.4496L13.4778 35.5714C14.7646 34.5835 16.577 34.6713 17.7624 35.7787L25.2951 42.413C26.439 43.3734 27.879 43.9103 29.3725 43.9334C30.5402 43.9476 31.6888 43.6367 32.6897 43.0349L46.3037 35.1568V48.5635ZM46.3036 31.9087L31.2382 40.6853C29.9446 41.4517 28.3072 41.313 27.1609 40.3398L19.5591 33.6364C17.3804 31.7643 14.196 31.6495 11.8882 33.3599L2.76615 39.9942V20.2988C2.76615 18.7785 3.80279 17.3273 5.32316 17.3273H43.2628C44.8872 17.3946 46.199 18.6766 46.3036 20.2988V31.9087ZM54.1154 14.1898C54.1144 14.199 54.1136 14.2082 54.1126 14.2173L50.8646 42.2748C50.8701 43.0023 50.5384 43.6913 49.9662 44.1406C49.6897 44.4171 49.0677 44.5553 49.0677 44.6935V20.2988C48.9586 17.1505 46.4121 14.6343 43.2627 14.563H10.2298L10.8517 9.17264C10.9867 8.47462 11.3516 7.84208 11.8884 7.37581C12.4945 6.95676 13.2273 6.76139 13.9617 6.8229L51.625 11.1766C53.1446 11.3209 54.2597 12.67 54.1154 14.1898Z"
            fill="#FF7E06"
          />
          <path
            d="M50.5 27C57.9558 27 64 20.9558 64 13.5C64 6.04416 57.9558 0 50.5 0C43.0442 0 37 6.04416 37 13.5C37 20.9558 43.0442 27 50.5 27Z"
            fill="#FF7E06"
          />
          <path
            d="M51.0391 8.08545V17.9946"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
          />
          <path
            d="M55.9961 13.04H46.0869"
            stroke="white"
            stroke-width="1.6"
            stroke-linecap="round"
          />
        </svg>
        <input type="file" accept={accept} name={name} onChange={onChange} />
        {/* {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null} */}
      </div>
    </>
  );
};

export const ReactMultiSelect = ({
  label,
  onBlur,
  options,
  isRequired = false,
  handleChange,
  value,
  valueAssigned,
  disabled = false,
  ...props
}) => {
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
    control: (base) => ({
      ...base,
      height: 46,
      minHeight: 32,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "32px",
      minHeight: 32,
      position: "initial",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
    placeholder: (provided) => ({
      ...provided,

      height: "30px",
      lineHeight: "30px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      height: "32px",
      lineHeight: "32px",
      // return { ...provided, height, lineHeight };
    }),
  };
  const [field, meta, helpers] = useField(props);

  return (
    <div>
      <label htmlFor={props.id}>
        {label} {isRequired && <span style={{ color: "#E67A66" }}>*</span>}
      </label>
      <Select
        id={props.id}
        options={options}
        multi={true}
        onChange={(e) => handleChange(e)}
        value={value}
        maxMenuHeight={220}
        styles={customStyles}
        isDisabled={disabled}
        placeholder={props.placeholder}
      />
      {meta.error ? (
        <div
          style={{ color: "#dc3545", marginTop: ".15rem", display: "block" }}
          className="invalid-feedback"
        >
          {(console.log(meta.error), meta.error)}
        </div>
      ) : null}
    </div>
  );
};

export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  props.className = modifyClasses(props.className, meta);

  return (
    <>
      {label && <label htmlFor={props.id || props.name}>{label} {props.isRequired && <span style={{ color: "#E67A66" }}>*</span>}</label>}
      <textarea {...field} {...props}></textarea>
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};

/** This function tests the if the string is a valid url or not
 * It is good for tiny url validation only,
 * it may make the DOM unresponsive if long url is pasted
 */
export const urlValidation = (msg = "") => {
  return Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    msg
  );
};

export const Button = ({
  label,
  dirty,
  errors,
  isValidating,
  isSubmitting,
  className = "btn btn-outline-primary",
  processingMsg = "Saving ...",
  ...props
}) => {
  return (
    <button
      type="submit"
      className={className}
      style={{ backgroundColor: "#FF7E06" }}
      disabled={
        /** !dirty &&  Object.keys(errors).length || */ isValidating ||
        isSubmitting
      }
      {...props}
    >
      {isValidating || isSubmitting ? (
        <>
          {/* <i className="fal fa-spinner fa-spin mr-1" aria-hidden="true"></i> */}
          {` ${processingMsg}`}
        </>
      ) : (
        label
      )}
    </button>
  );
};

export const SelectInput = ({
  label,
  labelClassname = "form-label",
  options,
  getOptionLabel,
  getOptionAnotherLabel,
  getOptionValue,
  isRequired = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  props.className = modifyClasses(props.className, meta);

  return (
    <>
      {label && (
        <label htmlFor={props.id || props.name} className={labelClassname}>
          {label}
          {isRequired && <span style={{ color: "#E67A66" }}>*</span>}
        </label>
      )}
      {/* <input {...field} {...props} /> */}

      <select {...field} {...props}>
        <option value="">Select </option>
        {options &&
          options.length > 0 &&
          options.map((option, index) => {
            const { label, value } = option;
            console.log(
              getOptionAnotherLabel,
              option[getOptionAnotherLabel],
              option
            );
            return (
              <option
                key={index}
                value={getOptionValue ? option[getOptionValue] : value}
              >
                {getOptionLabel && getOptionAnotherLabel
                  ? `${option[getOptionLabel]}, ${option[getOptionAnotherLabel]}`
                  : getOptionLabel
                    ? option[getOptionLabel]
                    : label}
              </option>
            );
          })}
      </select>

      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </>
  );
};
