class Regex {
  static email(email) {
    // eslint-disable-next-line no-useless-escape
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }
  // static mobile = val => /^\+(?:[0-9] ?){6,14}[0-9]$/.test(val);
  static mobile = (val) => /^[1-9][0-9]{9,12}$/.test(val);

  static string = (val) => /^\S[a-zA-Z\x20]{2,25}$/.test(val);

  /** Minimum six characters, at least one letter, one number and one special character: */
  static password = (val) =>
  /^(?=.*[A-Za-z])[A-Za-z0-9]\S{7,16}$/.test(val);
  static _password = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;

  // static numbers = val => /^[0-9]{0,}$/.test(val);
  static numbers = (val) => /^[0-9]*$/.test(val);

  static validateImageFile = (imageExtension) =>
    /(jpg|jpeg|png|PNG|JPG)$/.test(imageExtension);
  static validateString = (val) => /^\S[a-zA-Z\x20]{2,25}$/.test(val);
  static validateVideoFile = (videoExtension) => /(mp4)$/.test(videoExtension);
  static validateDocFile = (docExtension) =>
    /(pdf|doc|docx|pptx|xlsx|xls)$/.test(docExtension);
}

export default Regex;
