import React, { useState } from "react";
import { FormGroup, FormFeedback, Input, Form } from "reactstrap";
import Regex from "../utilities/regex";
import { push } from "connected-react-router";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

function SetNewPassword() {
  const [initialValues, setInitialValues] = useState({
      confirmPassword: "",
      newPassword: "",
    }),
    [showPassword, toggleShowPassword] = useState(false),
    [errors, setErrors] = useState({
      confirmPassword: false,
      newPassword: false,
      newPasswordError: "",
      confirmPasswordError: "",
    });

  const onHandleChange = ({ target: { name, value } }) => {
    const { errors } = this.state;
    setErrors({
      ...errors,
      [name]: false,
    });

    setInitialValues({
      ...initialValues,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { newPassword, confirmPassword } = initialValues;

    if (!Regex.password(newPassword.trim())) {
      setErrors({
        ...errors,
        newPassword: true,
        newPasswordError:
          "Please enter a valid password with 08-16 characters long and should be alphanumeric.",
      });

      return;
    }
    if (!confirmPassword.trim()) {
      setErrors({
        ...errors,
        confirmPassword: true,
        confirmPasswordError: "Please confirm your password.",
      });

      return;
    }

    if (newPassword.trim() !== confirmPassword.trim()) {
      setErrors({
        ...errors,
        confirmPasswordError: "Password & confirm password doesn't match.",
        confirmPassword: true,
      });

      return;
    }
  };

  return (
    <div className="col-12 inner-page-content login-page px-0  login-100 d-flex justify-content-center align-items-center ">
      <div id="login" className="col-sm-12  my-4">
        <div className="login-container col-sm-8 col-md-6 col-lg-4 col-xl-4 m-auto bg-white boxshadow border-radius8">
          <div
            id="login-row"
            className="row justify-content-center align-items-center "
          >
            <div id="login-column" className="w-100">
              <h3 className="text-center page-head font-weight-bold  txtcolor">
                Create New Password
              </h3>
              <h5 className="col-md-10 px-md-4 m-auto text-center txt-lghtblue page-subhead  pb-2">
                Your new password must be different from previous used password
              </h5>
              <div id="login-box" className="col-md-12 mt-4">
                <Form className="form site-form" onSubmit={onSubmit}>
                  <div className="form-group  mb-4">
                    <FormGroup>
                      <Input
                        name="newPassword"
                        className="form-control input-style min-input"
                        placeholder="New password"
                        type="password"
                        value={initialValues.newPassword}
                        invalid={errors.newPassword}
                        onChange={onHandleChange}
                      />
                      <FormFeedback>{errors.newPasswordError}</FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="form-group position-relative  mb-2">
                    <FormGroup className="form-group position-relative  mb-4">
                      <Input
                        id="confirmPassword"
                        type={showPassword ? "type" : "password"}
                        name="confirmPassword"
                        onChange={onHandleChange}
                        className="form-control input-style min-input"
                        placeholder="Confirm password"
                        value={initialValues.confirmPassword}
                        invalid={errors.confirmPassword}
                      />
                      <a
                        // href="/#"
                        className="pwd-show"
                        onClick={() => toggleShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </a>

                      <FormFeedback id="password-err">
                        {errors.confirmPasswordError}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="form-group mt-4 mb-0">
                    <button
                      type="submit"
                      className="btn  text-white w-100 btn-custom min-input mt-3"
                    >
                      <span>Sign in</span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetNewPassword;
