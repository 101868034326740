import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="container">
      <div className="notFound">
        <Link to={process.env.PUBLIC_URL+"/"}>
          <img src="/assets/images/404.png" width="100%" alt="" />
        </Link>
        <h2>Oops! Nothing was found</h2>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <Link to={process.env.PUBLIC_URL+"/"}>{"Return To Homepage"}</Link>
      </div>
    </div>
  );
};

export default NotFound;
