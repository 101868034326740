import { all, fork } from "redux-saga/effects";
import user from "./user";
import product from "./product";
import category from "./category";
import subCategory from "./subCategory";
import session from "./session";
import notification from "./notification";

export default function* rootSaga() {
  yield all([
    fork(user),
    fork(product),
    fork(category),
    fork(subCategory),
    fork(session),
    fork(notification)
  ]);
}
