import { auth } from "./index";

export const signIn = async ({ email, password }) => {
  return await auth.signInWithEmailAndPassword(email, password);
};

export const getIdToken = async (user) => {
  return await user.getIdToken();
};

export const getCurrentUserToken = async() => {
 if(auth.currentUser){
  let idToken = await auth.currentUser.getIdToken(true);
  return idToken
 }else{
     return null;
 }
    
}
