import { db, auth } from "./index";
import bcrypt from "bcryptjs";

/**Generic query for adding a document to collection  */
export const add = async (collection, data) => {
  return await db
    .collection(collection)
    .add({
      createdAt: Date.now(),
      isDeleted: false,
      ...data,
    })
    .then(function(docRef) {
      console.log("Document written with ID: ", docRef.id);
      let data = {
        id: docRef.id,
      };
      return data;
    });
};

/**Generic query for updating a document on a collection  */
export const update = async (collection, id, data) => {
  return await db
    .collection(collection)
    .doc(id)
    .update({
      ...data,
    });
};

/**Generic query for deleting a document on a collection */
export const deleteQ = async (collection, id) => {
  return await db
    .collection(collection)
    .doc(id)
    .delete();
};

/**Generic query for fetching document by Id from a collection */
export const getById = async (collection, id) => {
  let doc = await db
    .collection(collection)
    .doc(id)
    .get();
  if (!doc.exists) {
    throw new Error(`Not found!!`);
  } else {
    return { id, ...doc.data() };
  }
};

/**Generic query for fetching  documents  from a collection */
export const fetch = async (collection) => {
  let docs = [];
  let docsSnapshot = await db.collection(collection).get();
  docsSnapshot.forEach((doc) => docs.push({ id: doc.id, ...doc.data() }));
  return docs;
};

/**Populate Array of Ids field in a collection */
export const populateData = async (collection, arrayOfIds) => {
  let allPromises = arrayOfIds.map((id) => getById(collection, id));
  return await Promise.all(allPromises);
};

/**Generic query for updating multiple documents in a collection */
export const updateMany = async (
  collection,
  fieldName,
  value,
  operator,
  data
) => {
  console.log("Field name", fieldName, operator, value);
  db.collection(collection)
    .where(fieldName, operator, value)
    .onSnapshot(
      (records) => {
        records.forEach(async (record) => {
          return await update(collection, record.id, data);
        });
      },
      (err) =>
        console.log(
          `Error while fetching/updating contactForm snapshot: ${err.message}`
        )
    );
};

export const fetchSubCategoryFields = async (id) => {
  // let docs = [];
  // await db.collection("subCategoryFields")
  //   .where("subCategoryId", "==", id)
  //   .onSnapshot(
  //     (records) => {
  //       records.forEach((record) =>
  //         docs.push({ id: record.id, ...record.data() })
  //       );

  //     },
  //     (err) =>
  //       console.log(
  //         `Error while fetching/updating sub categories snapshot: ${err.message}`
  //       )
  //   );

  // return docs;
  let docs = [];
  let docsSnapshot = await db
    .collection("subCategoryFields")
    .where("subCategoryId", "==", id)
    .get();

  docsSnapshot.forEach((doc) => docs.push({ id: doc.id, ...doc.data() }));
  return docs;
};

/**Query for updating auth password and password in users table */
export const updatePassword = async (newPassword) => {
  try {
    const user = auth.currentUser;
    let password = await bcrypt.hash(newPassword, 10);

    await user.updatePassword(newPassword);
    return await update("users", user.uid, { password: password });
  } catch (error) {
    throw error;
  }
};
