import { HIDE_LOADER, SHOW_LOADER } from "../actions/app-action-types";
import { LOGOUT_SUCCESS } from "../actions/session-action-types";

const initialState = {
  isLoading: false,
};

export default function app(state = initialState, { payload, type }) {
  switch (type) {
    case HIDE_LOADER:
      return {
        ...state,
        isLoading: false,
      };

    case SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
