import { UPDATE_USERS } from "../actions/user-action-types";
import { LOGOUT_SUCCESS } from "../actions/session-action-types";

const initialState = {
  users: [],
};
export default function(state = initialState, { payload, type }) {
  switch (type) {
    case UPDATE_USERS:
      return {
        ...state,
        users: payload,
      };

    case LOGOUT_SUCCESS:
      console.log("Initial State on logout", initialState);
      return { ...initialState };

    

    default:
      return state;
  }
}
