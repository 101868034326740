import React from "react";
import { PersistGate } from "redux-persist/es/integration/react";
import { Provider, connect } from "react-redux";
import { createBrowserHistory as createHistory } from "history";
import Routers from "../navigation";
import configureStore from "./configureStore";
import Loader from "../components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConnectedRouter } from "connected-react-router";
import "react-confirm-alert/src/react-confirm-alert.css";
import RealtimeCapture from "../realtimeUpdates";

export const history = createHistory();

// store configration
const { persistor, store } = configureStore(history);

//global loader for the whole app
const ReduxLoader = connect((state) => ({ loading: state.app.isLoading }))(
  Loader
);

/** Assign store to window global object to access in firebase methods or anywhere needed */
window.store = store;

export default () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RealtimeCapture />
        <ToastContainer pauseOnFocusLoss={false} autoClose={5000} />
        <ConnectedRouter history={history}>
          <Routers store={store} history={history} />
        </ConnectedRouter>
        <ReduxLoader />
      </PersistGate>
    </Provider>
  );
};
