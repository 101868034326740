import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectModal from "./stepTwoModal";
import AddModal from "./stepThreeModal";
import { push } from "connected-react-router";

import DeleteModal from "../../components/deleteModal";
import { deleteSubCategoryField } from "../../redux/actions/sub-category-action-types";
import { SVG } from "../../components/svgComponent";
import { capitalizeFirst } from "../../utilities/methods";
import { useParams } from "react-router-dom";
import {
  fetchSubCategoryFields,
  fetchSubCategory,
} from "../../redux/actions/sub-category-action-types";

function SubCategoryFields() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectFieldModal, setSelectFieldModal] = useState(false),
    [addFieldModal, setAddFieldModal] = useState(false),
    [showDeleteModal, setShowDeleteModal] = useState(false),
    // [subCategory, setSubCategory] = useState({}),
    [initialValues, setInitialValues] = useState({
      subCategory: "",
      subCategoryId: "",
    }),
    [selectedField, setSelectedField] = useState({});

  useEffect(() => {
    /**Fetching sub category fields */

    dispatch(
      fetchSubCategoryFields({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    /**Fetching sub category data */
    dispatch(
      fetchSubCategory({
        id,
        callback: (data) => {
          // console.log("Data: ", data);
          // setSubCategory(data);
          setInitialValues({
            subCategoryId: id,
            subCategory: data.title,
          });
        },
      })
    );
  }, [dispatch, id]);

  const subCategory = useSelector((state) => state.subCategory);
  const fields = subCategory?.subCategoryFields;

  // console.log("Fields: ", subCategory);

  const selectFieldToggle = (field = null) => {
    setInitialValues({
      ...initialValues,
      fieldType: field ? field.fieldType : "",
    });
    setSelectFieldModal(!selectFieldModal);
  };
  const addFieldToggle = (field) => {
    // console.log("Inside add field toggle : ", field, initialValues);
    setInitialValues({
      ...initialValues,
      fieldType: field.fieldType,
      defaultValue: field.defaultValue,
      title: field.title,
      isRequiredField: field.isRequiredField,
      isUniqueField: field.isUniqueField,
    });
    setAddFieldModal(!addFieldModal);
  };

  const toggleDeleteModal = (field = null) => {
    setSelectedField({
      ...selectedField,
      ...field,
    });
    setShowDeleteModal(!showDeleteModal);
  };

  return (
    <div className="mainContentWrapper">
      <div className="dashContentHeader d-flex justify-content-between align-items-center">
        <div>
          <h5 className="dashHeading mb-0">
            Subcategory{" "}
            <svg
              width={18}
              height={18}
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.16602 13.9983H6.69935C6.80902 13.999 6.91774 13.9779 7.01927 13.9365C7.1208 13.895 7.21315 13.8339 7.29102 13.7567L13.0577 7.98165L15.4243 5.66499C15.5025 5.58752 15.5645 5.49535 15.6068 5.3938C15.6491 5.29225 15.6708 5.18333 15.6708 5.07332C15.6708 4.96331 15.6491 4.85439 15.6068 4.75284C15.5645 4.65129 15.5025 4.55912 15.4243 4.48165L11.891 0.906655C11.8135 0.828548 11.7214 0.766553 11.6198 0.724245C11.5183 0.681938 11.4094 0.660156 11.2993 0.660156C11.1893 0.660156 11.0804 0.681938 10.9789 0.724245C10.8773 0.766553 10.7852 0.828548 10.7077 0.906655L8.35768 3.26499L2.57435 9.03999C2.49711 9.11785 2.43601 9.2102 2.39454 9.31173C2.35307 9.41326 2.33205 9.52198 2.33268 9.63165V13.165C2.33268 13.386 2.42048 13.598 2.57676 13.7542C2.73304 13.9105 2.945 13.9983 3.16602 13.9983ZM11.2993 2.67332L13.6577 5.03165L12.4743 6.21499L10.116 3.85665L11.2993 2.67332ZM3.99935 9.97332L8.94102 5.03165L11.2993 7.38999L6.35768 12.3317H3.99935V9.97332ZM16.4993 15.665H1.49935C1.27834 15.665 1.06637 15.7528 0.910093 15.9091C0.753813 16.0653 0.666016 16.2773 0.666016 16.4983C0.666016 16.7193 0.753813 16.9313 0.910093 17.0876C1.06637 17.2439 1.27834 17.3317 1.49935 17.3317H16.4993C16.7204 17.3317 16.9323 17.2439 17.0886 17.0876C17.2449 16.9313 17.3327 16.7193 17.3327 16.4983C17.3327 16.2773 17.2449 16.0653 17.0886 15.9091C16.9323 15.7528 16.7204 15.665 16.4993 15.665Z"
                fill="#969696"
              />
            </svg>
          </h5>
        </div>
        <div className="d-flex">
          <button
            className="cancelbutton mr-lg-3 mr-md-2"
            onClick={(e) => {
              e.preventDefault();
              dispatch(push("/sub-category"));
            }}
          >
            Cancel
          </button>
          {/* <button className="delete-Button mr-lg-3 mr-md-2">Delete</button>
          <button
            className="saveBtn"
            type="button"
            onClick={(e) => onSubmit(e)}
          >
            Save
          </button> */}
        </div>
      </div>
      <div className="categoryListingTable bg-white p-6">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="modalBodyHeading mb-0">{fields.length} Field(s)</h6>
          <button
            className="addanotherField"
            type="button"
            onClick={selectFieldToggle}
          >
            <svg
              width={12}
              height={12}
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.25 5.25H6.75V0.75C6.75 0.551088 6.67098 0.360322 6.53033 0.21967C6.38968 0.0790178 6.19891 0 6 0C5.80109 0 5.61032 0.0790178 5.46967 0.21967C5.32902 0.360322 5.25 0.551088 5.25 0.75V5.25H0.75C0.551088 5.25 0.360322 5.32902 0.21967 5.46967C0.0790178 5.61032 0 5.80109 0 6C0 6.19891 0.0790178 6.38968 0.21967 6.53033C0.360322 6.67098 0.551088 6.75 0.75 6.75H5.25V11.25C5.25 11.4489 5.32902 11.6397 5.46967 11.7803C5.61032 11.921 5.80109 12 6 12C6.19891 12 6.38968 11.921 6.53033 11.7803C6.67098 11.6397 6.75 11.4489 6.75 11.25V6.75H11.25C11.4489 6.75 11.6397 6.67098 11.7803 6.53033C11.921 6.38968 12 6.19891 12 6C12 5.80109 11.921 5.61032 11.7803 5.46967C11.6397 5.32902 11.4489 5.25 11.25 5.25Z"
                fill="#FF7E06"
              />
            </svg>
            Add Another Field
          </button>
        </div>
        <div className="categoryFieldsTable mt-4 table-responsive">
          <table className="table">
            <tbody>
              {fields.length
                ? fields.map((field) => (
                    <tr className="active" key={field.id}>
                      <td>
                        <span>
                          <svg
                            width={12}
                            height={18}
                            viewBox="0 0 12 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 4C10.3956 4 10.7822 3.8827 11.1111 3.66294C11.44 3.44318 11.6964 3.13082 11.8478 2.76537C11.9991 2.39992 12.0387 1.99778 11.9616 1.60982C11.8844 1.22186 11.6939 0.865492 11.4142 0.585787C11.1345 0.306082 10.7781 0.115601 10.3902 0.0384303C10.0022 -0.0387401 9.60009 0.000866562 9.23463 0.152242C8.86918 0.303617 8.55682 0.559962 8.33706 0.88886C8.1173 1.21776 8 1.60444 8 2C8 2.53043 8.21072 3.03914 8.58579 3.41421C8.96086 3.78929 9.46957 4 10 4ZM10 14C9.60444 14 9.21776 14.1173 8.88886 14.3371C8.55996 14.5568 8.30362 14.8692 8.15224 15.2346C8.00087 15.6001 7.96126 16.0022 8.03843 16.3902C8.1156 16.7781 8.30608 17.1345 8.58579 17.4142C8.86549 17.6939 9.22186 17.8844 9.60982 17.9616C9.99778 18.0387 10.3999 17.9991 10.7654 17.8478C11.1308 17.6964 11.4432 17.44 11.6629 17.1111C11.8827 16.7822 12 16.3956 12 16C12 15.4696 11.7893 14.9609 11.4142 14.5858C11.0391 14.2107 10.5304 14 10 14ZM10 7C9.60444 7 9.21776 7.1173 8.88886 7.33706C8.55996 7.55682 8.30362 7.86918 8.15224 8.23463C8.00087 8.60009 7.96126 9.00222 8.03843 9.39018C8.1156 9.77814 8.30608 10.1345 8.58579 10.4142C8.86549 10.6939 9.22186 10.8844 9.60982 10.9616C9.99778 11.0387 10.3999 10.9991 10.7654 10.8478C11.1308 10.6964 11.4432 10.44 11.6629 10.1111C11.8827 9.78224 12 9.39556 12 9C12 8.46957 11.7893 7.96086 11.4142 7.58579C11.0391 7.21071 10.5304 7 10 7Z"
                              fill="#FF7E06"
                            />
                            <path
                              d="M2 4C2.39556 4 2.78224 3.8827 3.11114 3.66294C3.44004 3.44318 3.69639 3.13082 3.84776 2.76537C3.99914 2.39992 4.03874 1.99778 3.96157 1.60982C3.8844 1.22186 3.69392 0.865492 3.41421 0.585787C3.13451 0.306082 2.77814 0.115601 2.39018 0.0384303C2.00222 -0.0387401 1.60009 0.000866562 1.23463 0.152242C0.869182 0.303617 0.556825 0.559962 0.337062 0.88886C0.117299 1.21776 1.07779e-06 1.60444 1.07779e-06 2C1.07779e-06 2.53043 0.210715 3.03914 0.585788 3.41421C0.960861 3.78929 1.46957 4 2 4ZM2 14C1.60444 14 1.21776 14.1173 0.888861 14.3371C0.559963 14.5568 0.303617 14.8692 0.152242 15.2346C0.000866562 15.6001 -0.0387401 16.0022 0.0384303 16.3902C0.115601 16.7781 0.306083 17.1345 0.585788 17.4142C0.865493 17.6939 1.22186 17.8844 1.60982 17.9616C1.99778 18.0387 2.39992 17.9991 2.76537 17.8478C3.13082 17.6964 3.44318 17.44 3.66294 17.1111C3.8827 16.7822 4 16.3956 4 16C4 15.4696 3.78929 14.9609 3.41421 14.5858C3.03914 14.2107 2.53043 14 2 14ZM2 7C1.60444 7 1.21776 7.1173 0.888861 7.33706C0.559963 7.55682 0.303617 7.86918 0.152242 8.23463C0.000866562 8.60009 -0.0387401 9.00222 0.0384303 9.39018C0.115601 9.77814 0.306083 10.1345 0.585788 10.4142C0.865493 10.6939 1.22186 10.8844 1.60982 10.9616C1.99778 11.0387 2.39992 10.9991 2.76537 10.8478C3.13082 10.6964 3.44318 10.44 3.66294 10.1111C3.8827 9.78224 4 9.39556 4 9C4 8.46957 3.78929 7.96086 3.41421 7.58579C3.03914 7.21071 2.53043 7 2 7Z"
                              fill="#FF7E06"
                            />
                          </svg>
                        </span>
                        <span className="ml-4 pl-2">
                          <SVG svgType={field.fieldType} />
                        </span>
                        <span className="ml-4">{field.title}</span>
                      </td>
                      <td>{capitalizeFirst(field.fieldType)}</td>
                      <td className="text-right">
                        <button className="btn-link border-0">
                          <svg
                            width={18}
                            height={18}
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.16602 13.9983H6.69935C6.80902 13.999 6.91774 13.9779 7.01927 13.9365C7.1208 13.895 7.21315 13.8339 7.29102 13.7567L13.0577 7.98165L15.4243 5.66499C15.5025 5.58752 15.5645 5.49535 15.6068 5.3938C15.6491 5.29225 15.6708 5.18333 15.6708 5.07332C15.6708 4.96331 15.6491 4.85439 15.6068 4.75284C15.5645 4.65129 15.5025 4.55912 15.4243 4.48165L11.891 0.906655C11.8135 0.828548 11.7214 0.766553 11.6198 0.724245C11.5183 0.681938 11.4094 0.660156 11.2993 0.660156C11.1893 0.660156 11.0804 0.681938 10.9789 0.724245C10.8773 0.766553 10.7852 0.828548 10.7077 0.906655L8.35768 3.26499L2.57435 9.03999C2.49711 9.11785 2.43601 9.2102 2.39454 9.31173C2.35307 9.41326 2.33205 9.52198 2.33268 9.63165V13.165C2.33268 13.386 2.42048 13.598 2.57676 13.7542C2.73304 13.9105 2.945 13.9983 3.16602 13.9983ZM11.2993 2.67332L13.6577 5.03165L12.4743 6.21499L10.116 3.85665L11.2993 2.67332ZM3.99935 9.97332L8.94102 5.03165L11.2993 7.38999L6.35768 12.3317H3.99935V9.97332ZM16.4993 15.665H1.49935C1.27834 15.665 1.06637 15.7528 0.910093 15.9091C0.753813 16.0653 0.666016 16.2773 0.666016 16.4983C0.666016 16.7193 0.753813 16.9313 0.910093 17.0876C1.06637 17.2439 1.27834 17.3317 1.49935 17.3317H16.4993C16.7204 17.3317 16.9323 17.2439 17.0886 17.0876C17.2449 16.9313 17.3327 16.7193 17.3327 16.4983C17.3327 16.2773 17.2449 16.0653 17.0886 15.9091C16.9323 15.7528 16.7204 15.665 16.4993 15.665Z"
                              fill="#969696"
                            />
                          </svg>
                        </button>
                        <button className="btn-link border-0">
                          <svg
                            width={16}
                            height={14}
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.50013 7.75H9.50013C9.69904 7.75 9.8898 7.67098 10.0305 7.53033C10.1711 7.38968 10.2501 7.19891 10.2501 7C10.2501 6.80109 10.1711 6.61032 10.0305 6.46967C9.8898 6.32902 9.69904 6.25 9.50013 6.25H6.50013C6.30121 6.25 6.11045 6.32902 5.9698 6.46967C5.82914 6.61032 5.75013 6.80109 5.75013 7C5.75013 7.19891 5.82914 7.38968 5.9698 7.53033C6.11045 7.67098 6.30121 7.75 6.50013 7.75ZM13.2501 0.25H2.75013C2.22247 0.254094 1.71304 0.44351 1.31088 0.785137C0.908727 1.12676 0.639434 1.59887 0.550078 2.11892C0.460722 2.63897 0.556988 3.17388 0.822048 3.63015C1.08711 4.08642 1.5041 4.43502 2.00013 4.615V11.5C2.00013 12.0967 2.23718 12.669 2.65914 13.091C3.08109 13.5129 3.65339 13.75 4.25013 13.75H11.7501C12.3469 13.75 12.9192 13.5129 13.3411 13.091C13.7631 12.669 14.0001 12.0967 14.0001 11.5V4.615C14.4962 4.43502 14.9131 4.08642 15.1782 3.63015C15.4433 3.17388 15.5395 2.63897 15.4502 2.11892C15.3608 1.59887 15.0915 1.12676 14.6894 0.785137C14.2872 0.44351 13.7778 0.254094 13.2501 0.25ZM12.5001 11.5C12.5001 11.6989 12.4211 11.8897 12.2805 12.0303C12.1398 12.171 11.949 12.25 11.7501 12.25H4.25013C4.05121 12.25 3.86045 12.171 3.7198 12.0303C3.57914 11.8897 3.50013 11.6989 3.50013 11.5V4.75H12.5001V11.5ZM13.2501 3.25H2.75013C2.55121 3.25 2.36045 3.17098 2.2198 3.03033C2.07914 2.88968 2.00013 2.69891 2.00013 2.5C2.00013 2.30109 2.07914 2.11032 2.2198 1.96967C2.36045 1.82902 2.55121 1.75 2.75013 1.75H13.2501C13.449 1.75 13.6398 1.82902 13.7805 1.96967C13.9211 2.11032 14.0001 2.30109 14.0001 2.5C14.0001 2.69891 13.9211 2.88968 13.7805 3.03033C13.6398 3.17098 13.449 3.25 13.2501 3.25Z"
                              fill="#969696"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      </div>
      {selectFieldModal && (
        <SelectModal
          isOpen={selectFieldModal}
          toggle={selectFieldToggle}
          nextToggle={addFieldToggle}
          subCategoryField={initialValues}
        />
      )}
      {addFieldModal && (
        <AddModal
          isOpen={addFieldModal}
          toggle={addFieldToggle}
          subCategoryField={initialValues}
          id={selectedField ? selectedField.id : null}
        />
      )}
      {showDeleteModal && selectedField && (
        <DeleteModal
          isOpen={showDeleteModal}
          toggle={toggleDeleteModal}
          id={selectedField.id}
          method={deleteSubCategoryField}
          content={`Are you sure you want to delete '${selectedField.title}' sub category field?`}
        />
      )}
    </div>
  );
}

export default SubCategoryFields;
