import { applyMiddleware, createStore, compose } from "redux";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import reducer from "../redux/reducers";
import { routerMiddleware } from "connected-react-router";
import rootSaga from "../redux/sagas";

// configured store under a development mode
export default function configureStore(history) {
  const sagaMiddleware = createSagaMiddleware();
  const middle =
    process.env.NODE_ENV === "production"
      ? compose(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
      : composeWithDevTools(
          applyMiddleware(sagaMiddleware, routerMiddleware(history))
        );
  const store = createStore(reducer(history), middle);
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);

  return { persistor, store };
}
