import React from "react";
import { useDispatch  } from "react-redux";
import { push } from "connected-react-router";

const Footer = ({ t }) => {
  let dispatch = useDispatch();
  return(
    <footer className="bg-black">
      <div className="footer-inner py-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 footer-grid">
              <div className="d-flex align-items-center">
                <img onClick={()=>{dispatch(push(`/`))}} style={{cursor:"pointer"}} src={process.env.PUBLIC_URL+"/assets/images/f-logo.svg"} alt="logo" />
                <p className="m-0 cpy-rght text-white ml-4">
                  Bid.com Copyright © 2020. All rights reserved
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-end foo-btm">
              <ul className="list-unstyled d-flex m-0 align-items-center flex-wrap">
                <li>
                  <a href="#">Terms & Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
