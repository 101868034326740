import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useSelector } from "react-redux";
import { logout } from "../redux/actions/session-action-types";
import { db, auth } from "../firebase";
import { push } from "connected-react-router";

function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const [defaultImage, setDefaultImage]=useState('')
  const handleLogout = (e) => {
    e.preventDefault();
    /** Google SignOut, Call logout saga even if not logged out by google, we have to signout from our app anyway. */
    console.log("Logout >>>");
    auth
      .signOut()
      .then(() => dispatch(logout()))
      .catch(() => dispatch(logout()));
  };

  const method = async () => {
    let docs = [];
    let docsSnapshot = await db.collection("settings").get();
    docsSnapshot.forEach((doc) => docs.push({ id: doc.id, ...doc.data() }));
    if (docs.length != 0) {
      setDefaultImage(docs[0]?.defaultImage) 
    }
  }

  useEffect(()=>{
    method()
  },[])

  return (
    <div className="main-bggrey">
      <header className="bg-white header py-2">
        <div className="container">
          <nav className="w-100  d-flex justify-content-between flex-wrap py-1 align-items-center">
            <div className="bid-logo">
              {/* <Link href="/user-dashboard"> */}
              <a href="#" onClick={(e)=>{e.preventDefault(); dispatch(push('/'))}} className="navbar-brand">
                <img src={process.env.PUBLIC_URL+"/assets/images/logo.svg"} alt="logo" />
              </a>
              {/* </Link> */}
            </div>
            <div className="bid-ac">
              <div className="dropdown profbtn">
                <UncontrolledDropdown setActiveFromChild>
                  <span>
                  <img style={{maxHeight:"30px", borderRadius:"100px"}} src={defaultImage ? defaultImage : ''} onError={(e)=> {e.target.onerror = null ; e.target.src = "/assets/images/user.png"}}  alt="pc" />
                    {/* <img src="/assets/images/pc.png" alt="pc" /> */}
                  </span>
                  <DropdownToggle tag="a">
                    <button
                      className="btn box-shadow-none dropdown-toggle pr-0"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {user.displayName}
                    </button>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu">
                    <DropdownItem className="dropdown-item">
                      <a onClick={(e)=>{e.preventDefault(); handleLogout(e)}} className="dropdown-item" href="#/">
                       Logout
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
