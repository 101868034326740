import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db, auth, firebase } from "./firebase";
import { updateProducts } from "./redux/actions/product-action-types";
import { updateCategories } from "./redux/actions/category-action-types";
import { updateSubCategories } from "./redux/actions/sub-category-action-types";
import { updateNotification } from "./redux/actions/notification-action-types";
import { updateUsers } from "./redux/actions/user-action-types";
import { logout } from "./redux/actions/session-action-types";
export const mapData = (records) => {
  let _records = [];
  records.forEach((record) =>
    _records.push({ id: record.id, ...record.data() })
  );
  return _records;
};

export const mapAsObject = (records) => {
  let _records = {};
  records.forEach(
    (record) => (_records[record.id] = { id: record.id, ...record.data() })
  );
  return _records;
};

export default function RealtimeUpdates() {
  console.log("Real time updates called....");
  /** Redux Variables */
  let isLoggedIn = useSelector((state) => state.session?.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    // auth.signOut()
    //   .then(() => console.log("Logged Out successfully."))
    //   .catch(() => console.log("Error while loggin out."))
    auth.onAuthStateChanged(function(user) {
      if(isLoggedIn){
        if (!user) dispatch(logout({ hideToast: true }));
      }
      
    });

    console.log(
      `isLoggedIn: ${isLoggedIn} ================================================>`
    );
    if (isLoggedIn) {
      /** Products snapshot listener */

      db.collection("products")
        .where("isDeleted", "==", false)
        .onSnapshot(
          (records) => {
            dispatch(updateProducts(mapData(records)));
          },
          (err) =>
            console.log(
              `Error while fetching/updating contactForm snapshot: ${err.message}`
            )
        );

      /** Categories snapshot listener */
      db.collection("categories")
        .where("isDeleted", "==", false)
        .onSnapshot(
          (records) => {
            dispatch(updateCategories(mapData(records)));
          },
          (err) =>
            console.log(
              `Error while fetching/updating categories snapshot: ${err.message}`
            )
        );

        db.collection("notifications")
        .onSnapshot(
          (records) => {
            dispatch(updateNotification(mapData(records)));
          },
          (err) =>
            console.log(
              `Error while fetching/updating contactForm snapshot: ${err.message}`
            )
        );

      /**Sub categories snapshot listener */
      db.collection("subCategories")
        .where("isDeleted", "==", false)
        .onSnapshot(
          (records) => {
            dispatch(updateSubCategories(mapData(records)));
          },
          (err) =>
            console.log(
              `Error while fetching/updating sub categories snapshot: ${err.message}`
            )
        );

      /**Users snapshot listener */
      db.collection("users")
        .where("isDeleted", "==", false)
        .onSnapshot(
          (records) => {
            dispatch(updateUsers(mapData(records)));
          },
          (err) =>
            console.log(
              `Error while fetching/updating contactForm snapshot: ${err.message}`
            )
        );
    }
  }, [dispatch, isLoggedIn]);

  return null;
}
