import React from "react";
import Helmet from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";

const PublicLayout = ({ component: Component, title, ...rest }) => (
  <div>
    <Helmet>
      <title>{`Bid - ${title}`}</title>
    </Helmet>
    <Header />
    <div className="col-12 wrapper main-bggrey p-0">
      <Component {...rest} />
      <Footer />
    </div>
  </div>
);

export default PublicLayout;
