import NotFound from "../components/NotFound";
import Dashboard from "../containers/dashboard";
import Login from "../containers/login";
import RegisteredUsers from "../containers/registered-users";
import Category from "../containers/category";
import Subcategory from "../containers/sub-category";
import SubCategoryDraft from "../containers/sub-category/viewDetails";
import PostProduct from "../containers/product/post-new-product";
import AddNewProduct from "../containers/product/addEditProduct";
import ProductDetails from "../containers/product/product-details";
import Products from "../containers/product";
import ChangePassword from "../containers/change-password";
import ForgotPassword from "../containers/forgot-password";
import MailSent from "../containers/mail-sent";
import SetNewPassword from "../containers/set-new-password";

const Routes = [
  {
    component: Login,
    path: "/",
    title: "Log In",
    to: "/dashboard",
    type: "public",
  },

  {
    component: Dashboard,
    path: "/dashboard",
    title: "Dashboard",
    to: "/",
    type: "private",
  },

  {
    component: RegisteredUsers,
    path: "/registered-users",
    title: "Registered Users",
    to: "/",
    type: "private",
  },

  {
    component: Category,
    path: "/category",
    title: "Category",
    to: "/",
    type: "private",
  },
  {
    component: Subcategory,
    path: "/sub-category",
    title: "Sub Category",
    to: "/",
    type: "private",
  },

  {
    component: SubCategoryDraft,
    path: "/sub-category/:id",
    title: "Sub Category",
    to: "/",
    type: "private",
  },

  {
    component: PostProduct,
    path: "/add-new/:id?",
    title: "Post Product",
    to: "/",
    type: "private",
  },

  {
    component: AddNewProduct,
    path: "/add-new-product/:id?",
    title: "Add New Product",
    to: "/",
    type: "private",
  },

  {
    component: ProductDetails,
    path: "/product-detail/:id",
    title: "Product Details",
    to: "/",
    type: "private",
  },

  {
    component: Products,
    path: "/products",
    title: "Products",
    to: "/",
    type: "private",
  },

  {
    component: ChangePassword,
    path: "/change-password",
    title: "Change Password",
    to: "/",
    type: "private",
  },

  {
    component: ForgotPassword,
    path: "/forgot-password",
    title: "Forgot Password",
    to: "/dashboard",
    type: "public",
  },

  {
    component: MailSent,
    path: "/mail-sent",
    title: "Mail Sent",
    to: "/dashboard",
    type: "public",
  },

  {
    component: SetNewPassword,
    path: "/set-new-password",
    title: "Set New Password",
    to: "/dashboard",
    type: "public",
  },

  {
    component: NotFound,
    path: "*",
    title: "Not Found",
    type: "none",
  },
];

export default Routes;
