import { UPDATE_NOTIFICATION } from "../actions/notification-action-types";

const initialState = {
  notifications: []
};
export default function notifications(state = initialState, { payload, type }) {
  switch (type) {
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: payload,
      };

    default:
      return state;
  }
}
