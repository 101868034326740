import React from "react";
import PrivateHeader from "../components/private-header";
import Helmet from "react-helmet";
import Sidebar from "../components/sidebar";
import Footer from "../components/footer";

const PrivateLayout = ({
  logout,
  component: Component,
  path,
  title,
  ...rest
}) => (
  <React.Fragment>
    <Helmet>
      <title>{`Bid - ${title}`}</title>
    </Helmet>
    {title === "Product Details" && <PrivateHeader />}
    <div id="wrapper" className={title === "Product Details" && "product-item"}>
      {title !== "Product Details" && <Sidebar />}
      <Component {...rest} />
    </div>
    {title === "Product Details" && <Footer />}
  </React.Fragment>
);

export default PrivateLayout;
