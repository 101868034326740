import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { connectRouter } from "connected-react-router";
import encryptor from "./encryptor";
import compressor from "./compressor";
import app from "./app";
import user from "./user";
import session from "./session";
import product from "./product";
import category from "./category";
import subCategory from "./subCategory";
import notification from "./notification";

// store configurations
const storeConfig = {
  key: "primary",
  storage: storage,
  blacklist: ["app"],
  transforms: [encryptor, compressor],
};

// Combine all the reducers into one
const BidAdmin = (history) =>
  persistCombineReducers(storeConfig, {
    app,
    user,
    product,
    category,
    session,
    subCategory,
    notification,
    router: connectRouter(history),
  });

export default BidAdmin;
