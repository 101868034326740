import { all, call, takeLatest, put } from "redux-saga/effects";
import { add, update, getById } from "../../firebase/methods";
import { toast } from "react-toastify";
import {
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  FETCH_PRODUCT,
  MAKE_OFFER,
  CREATE_CUSTOMER
} from "../actions/product-action-types";

import { showLoader, hideLoader } from "../actions/app-action-types";
import {makeOfferMethod, createCustomerMethod} from "../../utilities/methods";

export function* addProduct({ payload: { values, callback } }) {
  try {
    yield put(showLoader());
    yield call(add, "products", values);
    yield put(hideLoader());

    if (callback) callback();
    toast.success("Product added successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    toast.error("An error occurred while creating category.");
  }
}

export function* fetchProduct({ payload: { id, callback } }) {
  try {
    console.log("Id", id);
    yield put(showLoader());
    let data = yield call(getById, "products", id);
    yield put(hideLoader());
    if (callback) callback(data);
  } catch (error) {
    yield put(hideLoader());
    console.log("Error", error);
    if (callback) callback();
    toast.error("An error occurred while fetching product.");
  }
}

export function* updateProduct({ payload: { id, values: data, callback } }) {
  try {
    console.log("Data", data);
    yield put(showLoader());
    yield call(update, "products", id, data);
    yield put(hideLoader());
    if (callback) callback();
    toast.success("Product updated successfully.");
  } catch (error) {
    console.log("Error", error);
    yield put(hideLoader());
    if (callback) callback();
    toast.error("An error occurred while updating product.");
  }
}

export function* deleteProduct({ payload: { id, callback } }) {
  try {
    yield put(showLoader());
    yield call(update, "products", id, { isDeleted: true });
    yield put(hideLoader());
    if (callback) callback();
    toast.success("Product deleted successfully.");
  } catch (error) {
    yield put(hideLoader());
    if (callback) callback();
    toast.error("An error occurred while deleting product");
  }
}

export function* makeOffer({ payload: { values, callback } }) {
  try{
    yield put(showLoader());
    const data = yield call(makeOfferMethod,values);
    yield put(hideLoader());
    if(callback) callback(data);
  }catch(error) {
    if (callback) callback();
    yield put(hideLoader());
    toast.error("An error occurred while making offer");
  }
}

export function* createCustomer({ payload: { values, callback } }) {
try{
  yield put(showLoader());
  const data = yield call(createCustomerMethod,values);
  yield put(hideLoader());
  if(callback) callback(data);
}catch(error) {
  if (callback) callback();
  yield put(hideLoader());
  toast.error("An error occurred while creating customer");
}
}

function* Products() {
  yield all([
    takeLatest(ADD_PRODUCT, addProduct),
    takeLatest(UPDATE_PRODUCT, updateProduct),
    takeLatest(DELETE_PRODUCT, deleteProduct),
    takeLatest(FETCH_PRODUCT, fetchProduct),
    takeLatest(MAKE_OFFER, makeOffer),
    takeLatest(CREATE_CUSTOMER, createCustomer)
  ]);
}

export default Products;
