import { UPDATE_PRODUCTS } from "../actions/product-action-types";
import { LOGOUT_SUCCESS } from "../actions/session-action-types";

const initialState = {
  products: [],
};
export default function(state = initialState, { payload, type }) {
  switch (type) {
    case UPDATE_PRODUCTS:
      return {
        ...state,
        products: payload,
      };
    case LOGOUT_SUCCESS:
      console.log("Initial State on logout", initialState);
      return { ...initialState };

    default:
      return state;
  }
}
