import React from "react";
import { Route, Redirect } from "react-router-dom";
import PublicLayout from "../layouts/public-layout";
import PrivateLayout from "../layouts/private-layout";
import Helmet from "react-helmet";

const AppRoute = ({
  component: Component,
  type = "private",
  authHandler,
  title,
  to = "/login",
  store,
  className = "",
  history,
  ...rest
}) => {
  let {
    session: { isLoggedIn },
  } = store.getState();

  return (
    <Route
      key={Date.now()}
      {...rest}
      exact
      render={(props) => {
        if (type === "none") {
          return (
            <React.Fragment>
              <Helmet>
                <title>{`Bid - ${title}`}</title>
              </Helmet>
              <Component title={title} key={Date.now()} {...props} />;
            </React.Fragment>
          );
        }
        if (type === "public" && !isLoggedIn) {
          return (
            <PublicLayout
              title={title}
              key={Date.now()}
              component={Component}
              className={className}
              {...props}
            />
          );
        }
        if (type === "private" && isLoggedIn) {
          return (
            <PrivateLayout
              title={title}
              key={Date.now()}
              component={Component}
              className={className}
              {...props}
            />
          );
        }
        if(type === "common"){
          console.log("Logged In", isLoggedIn);
          if(isLoggedIn){
            return (
              <PrivateLayout
                title={title}
                key={Date.now()}
                component={Component}
                className={className}
                {...props}
              />
            );
          }else{
            return (
              <PublicLayout
                title={title}
                key={Date.now()}
                component={Component}
                className={className}
                {...props}
              />
            );
          }
          
        }
        return (
          <Redirect
            to={{
              pathname: to,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default AppRoute;
