import React from "react";
function MailSent() {
  return (
    <div className="col-12 inner-page-content login-page px-0  login-100 d-flex justify-content-center align-items-center ">
      <div id="login" className="col-sm-12 py-4">
        <div className="login-container m-auto bg-white boxshadow border-radius8">
          <div
            id="login-row"
            className="row justify-content-center align-items-center "
          >
            <div id="login-column" className="w-100">
              <div className="col-12 d-flex justify-content-center align-items-center mail-img py-5 mt-3">
                <img src="/assets/images/mail.svg" alt="mail icon" />
              </div>
              <h3 className="text-center page-head font-weight-bold  txtcolor">
                Check Your Mail
              </h3>
              <h5 className="col-md-10 m-auto text-center txt-lghtblue page-subhead  pb-2 px-4">
                We have sent a password recover instructions to your email
              </h5>
              <div className="box-footer mt-5 pt-4">
                <p className=" col-md-10 m-auto px-md-4 text-center page-subhead p-lght">
                  Did not recieve the email? Check your spam filter, or try{" "}
                  <a href="/forgot-password">another email address</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MailSent;
