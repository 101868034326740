//import { push } from "connected-react-router";
import React, { useState, useEffect } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Formik, Form, setIn } from "formik";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";
import {
  ReactMultiSelect,
  TextInput,
  FileInput,
  TextArea,
  NumberInput,
} from "../../components/form-fields";
import * as Yup from "yup";
// import { storage } from "../firebase";

import { toast } from "react-toastify";
import { storage } from "../../firebase";
import {
  addProduct,
  updateProduct,
  fetchProduct,
} from "../../redux/actions/product-action-types";
import { fetchSubCategoryFields } from "../../redux/actions/sub-category-action-types";
import ProgressBar from "../../components/progressBar";

const fullYear = new Date().getFullYear();

const ProductSchema = Yup.object({
  name: Yup.string()
    .trim()
    .min(2, "Minimum of two keywords required")
    .max(50, "Maximum of 50 keywords allowed")
    .required("Listing name is required"),

  buyNowPrice: Yup.number()
    .integer()
    .required("Buy now price is required")
    .moreThan(
      Yup.ref("minimumOfferPrice"),
      "Buy now price should be greater than Minimum offer price"
    ),

  minimumOfferPrice: Yup.number()
    .integer()
    .required("Minimum offer price is required")
    .lessThan(
      Yup.ref("buyNowPrice"),
      "Minimum offer price should be less than Buy Now price"
    ),
  images: Yup.array(
    Yup.object({
      imageUrl: Yup.string()
        .trim()
        .required("Image url is required"),
      imagePath: Yup.string()
        .trim()
        .required("Image  path is required"),
    }).required("Image object is required")
  )
    .optional()
    .max(6, "Maximum of 6 photos are allowed"),
  description: Yup.string()
    .trim()
    .required("Listing description is required")
    .min(10, "Minimum of 10 keywords required")
    .max(1000, "Maximum of 1000 keywords required"),
  make: Yup.string()
    .trim()
    .optional(),
  model: Yup.string()
    .trim()
    .optional(),
  year: Yup.number().integer().max(fullYear, `Max year is ${fullYear}`).optional(),
  streetAddress: Yup.string()
    .trim()
    .required("Street Address"),
  address: Yup.string()
    .trim()
    .required("Address is required"),
  state: Yup.string()
    .trim()
    .required("State is required"),
  city: Yup.string()
    .trim()
    .required("City is required"),
  postalCode: Yup.string()
    .trim()
    .required("Postal code is required"),
  country: Yup.string()
    .trim()
    .required("Country is required"),
  delivery: Yup.string()
    .trim()
    .required("Delivery address is required"),
});

function AddEditProduct() {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  console.log("Location : ", location);
  const [address, setAddress] = useState("");
  const [latlng, setLatLng] = useState({ lat: "", lng: "" });

  /** State */
  const [initialValues, setInitialValues] = useState({
    categoryId: location.state?.categoryId,
    subCategoryId: location.state?.subCategoryId,
    productId: "",
    name: "",
    images: [],
    description: "",
    make: "",
    model: "",
    year: "",
    streetAddress: "",
    address: "",
    state: "",
    city: "",
    postalCode: "",
    country: "",
    delivery: "",
    condition: "",
    minimumOfferPrice: "",
    buyNowPrice: "",
  }),
    [isUploading, setIsUploading] = useState(false),
    [progress, setProgress] = useState(0);

  const [additionalFields, setAdditionalFields] = useState([]);
  const [inputValues, setInputValues] = useState({
    label: null,
  });
  const [show, setShow] = useState(false);
  const categories = useSelector((state) => state.category?.categories);
  const subCategories = useSelector((state) => state.subCategory.subCategories);
  const subCategoryFields = useSelector(
    (state) => state.subCategory.subCategoryFields
  );
  //console.log("Sub Category Fields", subCategoryFields);

  const categoryOptions = categories.map((category) => {
    return {
      key: category.id,
      label: category.title,
      value: category.id,
    };
  });

  const subCategoryOptions = subCategories.map((category) => {
    return {
      key: category.id,
      label: category.title,
      value: category.id,
      parentCategoryId: category.parentCategoryId,
    };
  });

  const year = new Date().getFullYear();
  const years = Array.from(new Array(10), (val, index) => year - index);
  const yearOptions = years.map((year) => {
    return {
      label: year,
      value: year,
    };
  });

  useEffect(() => {
    if (id) {
      dispatch(
        fetchProduct({
          id,
          callback: (data) => {
            console.log("Data", data);
            setInitialValues({
              productId: data.id,
              name: data.name,
              images: data.images,
              description: data.description ? data.description : "",
              make: data.make ? data.make : "",
              model: data.model ? data.model : "",
              year: data.year ? data.year : "",
              streetAddress: data.streetAddress ? data.streetAddress : "",
              address: data.address ? data.address : "",
              state: data.state ? data.state : "",
              city: data.city ? data.city : "",
              postalCode: data.postalCode ? data.postalCode : "",
              country: data.country ? data.country : "",
              delivery: data.delivery ? data.delivery : "",
              minimumOfferPrice: data.minimumOfferPrice
                ? data.minimumOfferPrice
                : "",
              buyNowPrice: data.buyNowPrice ? data.buyNowPrice : "",
              condition: data.condition ? data.condition : "",
              serialnumber: data.serialnumber ? data.serialnumber : "",
              enginehours: data.enginehours ? data.enginehours : "",
              header: data.header ? data.header : "",
              stocknumber: data.stocknumber ? data.stocknumber : "",
              combinetype: data.combinetype ? data.combinetype : "",
            });
            setAdditionalFields(data.extra ? data.extra : []);
            setAddress(data.address);
            if (data.latlng) {
              setLatLng(data.latlng);
            }
          },
        })
      );
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      fetchSubCategoryFields({
        id: location.state?.subCategoryId,
        callback: (data) => { },
      })
    );
  }, [dispatch, location.state?.subCategoryId]);

  const onCategoryChange = (e) => {
    setInitialValues({ ...initialValues, categoryId: e.value });
  };

  const onSubCategoryChange = (e) => {
    setInitialValues({ ...initialValues, subCategoryId: e.value });
  };

  const onYearChange = (e) => {
    setInitialValues({ ...initialValues, year: e.value });
  };

  const handleFireBaseUpload = async (e) => {
    e.preventDefault();
    if (initialValues.images.length < 6) {
      const imageFile = e.target.files[0];
      const fileName = `${imageFile.name}-${Date.now()}`;
      const imagePath = `/images/listing/${fileName}`;

      if (imageFile === "") {
        toast.error(`Not an image, the image file is a ${typeof imageFile}`);
      }

      //initiates the firebase side uploading
      const uploadTask = storage.ref(imagePath).put(imageFile);

      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setIsUploading(true);
          var progress = parseInt(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("Upload is " + progress + "% done");
          setProgress(progress);
        },
        function (error) {
          setProgress(0);
          setIsUploading(false);
          toast.error("Error uploading file.");
          // Handle unsuccessful uploads
        },
        async function () {
          // Handle successful uploads on complete
          console.log("File Uplaoaded : ", uploadTask);
          setIsUploading(false);

          let imageUrl = await storage
            .ref("images/listing")
            .child(fileName)
            .getDownloadURL();

          let images = [...initialValues.images];

          images.push({
            imagePath: imagePath,
            imageUrl: imageUrl,
          });

          console.log("Images in upload: ", images);

          setInitialValues({
            ...initialValues,
            images: images,
          });
        }
      );
    } else {
      toast.error("Your maximum file uploaded is reached!");
    }
  };

  /**Remove or delete file */
  const removeImage = async (e, idx) => {
    e.preventDefault();
    console.log("Initial values :", initialValues);

    let images = [...initialValues.images];
    console.log("Images : ", images);
    let removedFile = images[idx];
    console.log("Removed file :", removedFile);
    images = images.filter((i, index) => {
      return index !== idx;
    });

    await storage
      .ref()
      .child(removedFile.imagePath)
      .delete();

    setInitialValues({ ...initialValues, images: images });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "address") {
      setInitialValues({ ...initialValues, [name]: address });
    } else {
      setInitialValues({ ...initialValues, [name]: value });
    }
  };

  const handleOnAddInfoChange = (e, index) => {
    const { value } = e.target;
    const fields = [...additionalFields];
    fields[index] = { ...fields[index], value };
    setAdditionalFields(fields);
  }

  const handleRemoveExtra = (index) => {
    const fields = [...additionalFields];
    fields.splice(index, 1);
    setAdditionalFields(fields);
  }

  const onSubmit = (values) => {
    let data = {
      ...values,
      subCategoryName: location.state?.subCategoryName,
      categoryName: location.state?.categoryName,
      status: "unclaimed",
      address: address,
      latlng: latlng,
    };
    if (id) {
      values = { ...values, extra: additionalFields };
      if (address) {
        values.latlng = latlng;
        values.address = address;
      }
      const callback = async () => {
        dispatch(push("/products"));
      };
      dispatch(updateProduct({ id: id, values, callback }));
    } else {
      data = { ...data, extra: additionalFields };
      data.shareCode = Math.random()
        .toString(36)
        .substr(2, 6)
        .toUpperCase();
      const callback = async () => {
        dispatch(push("/products"));
      };
      dispatch(
        addProduct({
          values: data,
          callback,
        })
      );
    }
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        console.log("Address results : ", results[0].formatted_address);
        let formatted_address = results[0].formatted_address.split(",");
        const { address_components } = results[0];
        console.log("Address components : ", address_components);

        let cityIndex = address_components.findIndex(
          (i) => i.types[0] == "administrative_area_level_2"
        );
        let stateIndex = address_components.findIndex(
          (i) => i.types[0] == "administrative_area_level_1"
        );
        let countryIndex = address_components.findIndex(
          (i) => i.types[0] == "country"
        );
        let postalCodeIndex = address_components.findIndex(
          (i) => i.types[0] == "postal_code"
        );

        const stateElement = document.getElementById("state");
        const cityElement = document.getElementById("city");
        const countryElement = document.getElementById("country");

        if (stateIndex === -1)
          stateElement.disabled = false;
        else
          stateElement.disabled = true;
        if (cityIndex === -1)
          cityElement.disabled = false;
        else
          cityElement.disabled = true;
        if (countryIndex === -1)
          countryElement.disabled = false;
        else
          countryElement.disabled = true;

        console.log(
          "Indexes : ",
          cityIndex,
          stateIndex,
          countryIndex,
          postalCodeIndex
        );

        // if (countryIndex !== -1)
        //   console.log("Country_name: ", address_components[countryIndex].long_name);

        setInitialValues({
          ...initialValues,
          streetAddress: `${address_components[0] ? address_components[0].short_name : ''}${address_components[1] ? ', ' + address_components[1].short_name : ''}${address_components[3] ? ', ' + address_components[3].short_name : ''}`,
          address: `${address_components[0] ? address_components[0].short_name : ''}${address_components[1] ? ', ' + address_components[1].short_name : ''}${address_components[3] ? ', ' + address_components[3].short_name : ''}`,
          city: cityIndex !== -1 ? address_components[cityIndex].long_name : "",
          state:
            stateIndex !== -1 ? address_components[stateIndex].long_name : "",
          country:
            countryIndex !== -1
              ? address_components[countryIndex].long_name
              : "",
          postalCode:
            postalCodeIndex !== -1
              ? address_components[postalCodeIndex].long_name
              : "",
        });
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setLatLng(latLng);
        console.log("Initial values : ", initialValues);
      })
      .catch((error) => console.error("Error", error));
  };

  const addExtraInput = (e) => {
    e.preventDefault();
    if (additionalFields.length === 10)
      toast.error("Max limit for adding additional fields reached!")
    else if (!inputValues.label || (inputValues && inputValues.label.trim().length === 0))
      toast.error("input field can't be empty");
    else if (additionalFields.findIndex(field => field.label === inputValues.label) > -1)
      toast.error("An input field by this label already exists");
    else {
      setAdditionalFields([...additionalFields, {
        label: inputValues.label,
        value: ""
      }])
      setInputValues({ ...inputValues, label: null });
      document.getElementById("inputLabel").value = null;
    }

  }

  return (
    <div className="mainContentWrapper add-product">
      <>
        <div className="dashContentHeader px-5 mb-3 row">
          <h5 className="dashHeading mb-0 fontfammedium p-0">
            {!id ? "Add New Product" : "Edit Product"}
          </h5>
          <p className="col-12 p-0 text-lt mt-3 font-16">
            {!id ? "Please fill out the information below to create a new listing." : "Please fill out the information below to update listing."}
          </p>
        </div>
        <div className="row dashboardCardRow px-5">
          <div className="col-lg-8 bg-white mt-5 shadow">
            <Formik
              initialValues={initialValues}
              validationSchema={ProductSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ errors, values }) => (
                console.log("Errors : ", errors),
                (
                  <Form className="mg-login new-product mt-4 px-3">
                    <div className="row mb-0">
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Listing Title"
                          name="name"
                          id="name"
                          type="text"
                          placeholder="Title"
                          className="form-control "
                          onChange={handleOnChange}
                          isRequired
                          errors={errors}
                        />
                      </div>
                      <h5 className="dashHeading mb-0 mx-3 fontfammedium">
                        Upload Photo
                      </h5>
                      <p className="text-lt textp-lt  mt-2 mb-3 w-100 mx-3 font-16">
                        You can upload up max 6 photos.{" "}
                      </p>
                      <div className="col-md-12 mb-4 form-group d-flex flex-wrap">
                        {/* <div className="col-md-4 upload-product"> */}
                        <FileInput
                          className="col-md-4 upload-product mb-3"
                          name="myFile"
                          onChange={handleFireBaseUpload}
                          errors={errors}
                        ></FileInput>

                        {/* </div> */}

                        {values.images &&
                          values.images.map((image, index) => (
                            <div
                              className="col-md-4 upload-image mb-3"
                              key={index}
                            >
                              <img src={image.imageUrl} alt="Image File" />
                              <div className="delete-img">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={(e) => removeImage(e, index)}
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="11"
                                    fill="#FF7E06"
                                    stroke="white"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M15 9L9 15"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M9 9L15 15"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          ))}
                        <ProgressBar
                          isUploading={isUploading}
                          percentUploaded={progress}
                        />
                      </div>
                      <div className="col-12">
                        <hr className="w-100 mb-4" />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextArea
                          label="Description"
                          className="form-control"
                          name="description"
                          placeholder="Type here"
                          id="description"
                          onChange={handleOnChange}
                          errors={errors}
                          isRequired
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <ReactMultiSelect
                          label="CHOOSE A CATEGORY"
                          options={categoryOptions}
                          handleChange={onCategoryChange}
                          disabled={true}
                          name="categoryId"
                          id="categoryId"
                          value={categoryOptions.find((option) => {
                            return option.value === values.categoryId;
                          })}
                          placeholder="SELECT CATEGORY"
                          className="form-control form-select"
                          errors={errors}
                          isRequired
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <ReactMultiSelect
                          label="CHOOSE A SUB CATEGORY"
                          options={subCategoryOptions.filter((data) => {
                            return (
                              data.parentCategoryId === initialValues.categoryId
                            );
                          })}
                          handleChange={onSubCategoryChange}
                          name="subCategoryId"
                          disabled={true}
                          id="subCategoryId"
                          value={subCategoryOptions.find((option) => {
                            return option.value === values.subCategoryId;
                          })}
                          placeholder="CHOOSE CATEGORY FIRST"
                          className="form-control form-select"
                          errors={errors}
                          isRequired
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Make"
                          className="form-control"
                          id="make"
                          name="make"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Model"
                          className="form-control"
                          id="model"
                          name="model"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <NumberInput
                          label="Year"
                          onWheel={() => document.activeElement.blur()}
                          className="form-control"
                          id="year"
                          name="year"
                          placeholder="Enter Year"
                          errors={errors}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Condition"
                          className="form-control"
                          id="condition"
                          name="condition"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <NumberInput
                          label="Buy Now Price"
                          onWheel={() => document.activeElement.blur()}
                          className="form-control"
                          id="buyNowPrice"
                          name="buyNowPrice"
                          placeholder="Enter here.."
                          errors={errors}
                          isRequired
                          min="1"
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <NumberInput
                          label="Minimum Offer Price"
                          onWheel={() => document.activeElement.blur()}
                          className="form-control"
                          id="minimumOfferPrice"
                          name="minimumOfferPrice"
                          placeholder="Enter here.."
                          errors={errors}
                          isRequired
                          min="1"
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-12">
                        <hr className="w-100 mb-4" />
                      </div>

                      <h5 className="dashHeading mb-4 mt-3 mx-3 fontfammedium">
                        Add Location
                      </h5>
                      <div className="col-md-12 mb-4 form-group">
                        <PlacesAutocomplete
                          value={address}
                          onChange={(e) => {
                            setAddress(e);
                          }}
                          onSelect={(e) => {
                            setAddress(e);
                            handleSelect(e);
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <TextInput
                                label="Address"
                                className="form-control"
                                id="address"
                                name="address"
                                placeholder="Enter here.."
                                onChange={handleOnChange}
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className:
                                    "location-search-input form-control",
                                })}
                                isRequired
                                required
                              />
                              {/*<input
                                required
                                {...getInputProps({
                                  placeholder: 'Search Places ...',
                                  className: 'location-search-input form-control',
                                })}
                              />*/}
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                    : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Street Address"
                          className="form-control"
                          id="streetAddress"
                          name="streetAddress"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                          isRequired
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        {/* <label>Address </label>
                      <select id="inputState" className="form-control">
                        <option selected></option>
                        <option>...</option>
                      </select> */}
                        <TextInput
                          label="State"
                          className="form-control"
                          id="state"
                          name="state"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                          isRequired
                          disabled
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        {/* <label>city </label>
                      <select id="inputState" className="form-control">
                        <option selected></option>
                        <option>...</option>
                      </select> */}
                        <TextInput
                          label="City"
                          className="form-control"
                          id="city"
                          name="city"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                          isRequired
                          disabled
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        {/* <label>country </label>
                      <select id="inputState" className="form-control">
                        <option selected></option>
                        <option>...</option>
                      </select> */}
                        <TextInput
                          label="Country"
                          className="form-control"
                          id="country"
                          name="country"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                          isRequired
                          disabled
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Postal Code"
                          className="form-control"
                          id="postalCode"
                          name="postalCode"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                          isRequired
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        {/* <label>delivery </label>
                      <select id="inputState" className="form-control">
                        <option selected></option>
                        <option>...</option>
                      </select> */}
                        <TextInput
                          label="Delivery"
                          className="form-control"
                          id="delivery"
                          name="delivery"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                          isRequired
                        />
                      </div>

                      <div className="col-12">
                        <hr className="w-100 mb-4" />
                      </div>
                      <h5 className="dashHeading mb-2 mx-3 fontfammedium mt-3">
                        Additional Information
                      </h5>
                      <div className="col-12 mb-4">
                        <small>(maximum 10 additional fields are allowed)</small>
                      </div>
                      {/* <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Serial number"
                          className="form-control"
                          id="serialnumber"
                          name="serialnumber"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Stock number"
                          className="form-control"
                          id="stocknumber"
                          name="stocknumber"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Engine hours"
                          className="form-control"
                          id="enginehours"
                          name="enginehours"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Combine type"
                          className="form-control"
                          id="combinetype"
                          name="combinetype"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-md-12 mb-4 form-group">
                        <TextInput
                          label="Header"
                          className="form-control"
                          id="header"
                          name="header"
                          placeholder="Enter here.."
                          onChange={handleOnChange}
                        />
                      </div> */}

                      {additionalFields.map((field, index) => {
                        return (
                          <div key={index} className="col-md-12 mb-4 form-group">
                            <div className="row">
                              <div className="col-11">
                                <TextInput
                                  id={"additionalInput" + index}
                                  label={field.label}
                                  className="form-control"
                                  name={field.label}
                                  value={field.value}
                                  placeholder="Enter here.."
                                  onChange={(e) => handleOnAddInfoChange(e, index)}
                                />
                              </div>
                              <div className="col-1 d-flex justify-content-center align-items-center">
                                <button className="btn btn-danger" type="button" onClick={() => handleRemoveExtra(index)}>x</button>
                              </div>
                            </div>
                          </div>
                        )
                      })}

                      <div className="col-md-12 mb-4 form-group">
                        <button type="button" onClick={() => {
                          if (show)
                            setShow(false)
                          else
                            setShow(true)
                        }}>{show ? "-" : "+"}</button>
                        {show && <div id="addFieldForm" >

                          {/* <div className="form-group">
                              <label htmlFor="inputId">Id</label>
                              <input id="inputId" onChange={e => setInputValues({ ...inputValues, id: e.target.value })} placeholder="Enter id.." required />
                            </div> */}
                          <div className="form-group">
                            <label htmlFor="inputLabel" className="mr-3">Label</label>
                            <input id="inputLabel" onChange={e => setInputValues({ ...inputValues, label: e.target.value.trim().toLowerCase() })} placeholder="Enter label.." onKeyDown={(e) => { if (e.keyCode === 13) { addExtraInput(e) } }} />
                          </div>
                          {/* <div className="form-group">
                              <label htmlFor="inputName">Name</label>
                              <input id="inputName" onChange={e => setInputValues({ ...inputValues, name: e.target.value })} placeholder="Enter name.." required />
                            </div> */}
                          {/* <div className="form-group">
                              <label htmlFor="inputPlaceholder">Placeholder</label>
                              <input id="inputPlaceholder" onChange={e => setInputValues({ ...inputValues, placeholder: e.target.value })} placeholder="Enter placeholder" required />
                            </div> */}
                          <button type="button" onClick={addExtraInput}>Add Field</button>
                        </div>}
                      </div>

                      {subCategoryFields.map((field, key) => {
                        return (
                          <React.Fragment key={key}>
                            <div className="col-md-12 mb-4 form-group">
                              {field.fieldType === "media" && (
                                <FileInput
                                  label={`${field.title}`}
                                  className="col-md-4 upload-product"
                                  name="myFile"
                                  onChange={handleFireBaseUpload}
                                  errors={errors}
                                ></FileInput>
                              )}
                              {field.fieldType === "string" && (
                                <TextInput
                                  label={`${field.title}`}
                                  className="form-control"
                                  id="delivery"
                                  name={`${field.title}`}
                                  placeholder="Enter here.."
                                  onChange={handleOnChange}
                                />
                              )}
                              {field.fieldType === "number" && (
                                <TextInput
                                  label={`${field.title}`}
                                  className="form-control"
                                  id="delivery"
                                  name={`${field.title}`}
                                  placeholder="Enter here.."
                                  onChange={handleOnChange}
                                />
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}

                      {/* 
                    <h5 className="dashHeading mb-4 mx-3">
                      Additional Information (Based on Sub category fields)
                    </h5>
                    <div className="col-md-12 mb-4 form-group">
                      <label>Serial number</label>
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Enter here.."
                        value=""
                      />
                    </div>
                    <div className="col-md-12 mb-4 form-group">
                      <label>Stock number</label>
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Enter here.."
                        value=""
                      />
                    </div>
                    <div className="col-md-12 mb-4 form-group">
                      <label>Engine hours</label>
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Enter here.."
                        value=""
                      />
                    </div>
                    <div className="col-md-12 mb-4 form-group">
                      <label>Combine type</label>
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Enter here.."
                        value=""
                      />
                    </div>
                    <div className="col-md-12 mb-4 form-group">
                      <label>Header</label>
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Enter here.."
                        value=""
                      />
                    </div> */}
                    </div>
                    <div className="d-flex justify-content-start mb-5">
                      <button
                        type="submit"
                        // onClick={() => push("/product-detail")}
                        className="mb-5 sub-button mt-4 px-4"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )
              )}
            </Formik>
            <form className="mg-login new-product mt-4 px-3"></form>
          </div>
        </div>
      </>
    </div>
  );
}

/*export default GoogleApiWrapper({
  apiKey: "AIzaSyDribJAKGYCMHJ2eMt6SQfXW9hFjR3uqdg",
  libraries: ["places"]
})(AddEditProduct);*/

export default GoogleApiWrapper({
  apiKey: "AIzaSyBApvvMp-DolYwwyo3C2jpq6lGDpZzLeiU",
  libraries: ["places"],
})(AddEditProduct);
